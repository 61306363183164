import { emailRegExp, phoneRegExp } from '@/utils/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import { number, object, string } from 'yup';

import { pageUrls, ROLES } from '@/config/constants/keys';
import { BACKEND_DATE_FORMAT, UI_DATE_FORMAT } from '@/config/constants/static';
import {
  hasRestrictedRole,
  hasValue,
  objectArrayFromDiffFields,
  objectArrayFromSameField
} from '../../../utils/utils';

const layout = window.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`../../../components/UI/Form/${layout}`));
const Timezone = lazy(() => import(`@/components/modules/Timezone/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const ErrorIcon = lazy(() => import(`@/components/ErrorIcon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const CompanyDataForm = ({
  apiStatus,
  history,
  Styled,
  i18n,
  innerComponent,
  companyData,
  states,
  saveCompanyData,
  callback,
  cancelSubscription,
  allCycleRules,
  allCycleExceptions,
  allVehicleTypes,
  updateCardDetails,
  updateCard,
  insurancePortalUser,
  type
}) => {
  const [AddEditModalObject, setAddEditModalObject] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });
  const [addOpen, setAddOpen] = useState(false);
  const clickedAddEditModal = (e) => {
    setAddOpen(true);
  };
  const closeAddEditModal = (event) => {
    setAddOpen(false);
  };
  const apiId = type + 'SaveFleetCompany';
  //Check whether the api is calling
  const hasSaveApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const RenderComponent = lazy(() => import(`@/${innerComponent}`));
  const radiusCompliances = objectArrayFromSameField(
    companyData?.radiusCompliances
  );
  const underWriters = objectArrayFromDiffFields(
    companyData?.underwriteres,
    'id',
    'fullName'
  );
  const lossControllers = objectArrayFromDiffFields(
    companyData?.lossControles,
    'id',
    'fullName'
  );
  const getTmezonePopup = (type, message, payload) => {
    payload = {
      ...payload,
      data: {
        ...payload.data,
        timeZone: companyData?.companyDto?.timeZoneId
      }
    };

    if (type === 'error') {
      setAddEditModalObject({
        header: '',
        content: () => {
          return (
            <div className="successpopup">
              <ErrorIcon />
              <h2> {i18n.t(message)} </h2>
              <div className="deletePopupBtn">
                <Button
                  type="submit"
                  label={i18n.t('common.ok')}
                  onClick={() => {
                    closeAddEditModal();
                  }}
                />
              </div>
            </div>
          );
        }
      });
      clickedAddEditModal();
    }
    if (message === 'Time zone not available') {
      setAddEditModalObject({
        header: '',
        content: () => {
          return (
            <Timezone
              closeModal={closeAddEditModal}
              callback={callback}
              payload={payload}
              saveCompanyData={saveCompanyData}
              successCallback={() => {
                history.push(pageUrls.fleetCompanies);
              }}
            />
          );
        }
      });
      clickedAddEditModal();
    }
  };
  return (
    <>
      <Form
        type="server"
        direction="column"
        initialValues={{
          autoPaymentEnabled:( type==="add"?true:companyData?.companyDto?.autoPaymentEnabled)||false,
          freeTrialEndDate: companyData?.companyDto?.freeTrialEndDate? moment(companyData?.companyDto?.freeTrialEndDate).format(UI_DATE_FORMAT):"",
          companyId: companyData?.companyDto?.companyId || '',
          company: companyData?.companyDto?.company || '',
          dotNumber: companyData?.companyDto?.dotNumber || '',
          address: companyData?.companyDto?.address || '',
          city: companyData?.companyDto?.city || '',
          speedLimit: companyData?.companyDto?.speedLimit || '',
          zohoId: companyData?.companyDto?.zohoCustomerId || '',
          state: companyData?.companyDto?.state
            ? {
                label: companyData?.companyDto?.state,
                value: companyData?.companyDto?.state
              }
            : '',
          insuranceExpDate: companyData?.companyDto?.insuranceExpDate? moment(companyData?.companyDto?.insuranceExpDate).format(UI_DATE_FORMAT): '',
          radiusCompliance: companyData?.companyDto?.radiusCompliance
            ? {
                label: companyData?.companyDto?.radiusCompliance,
                value: companyData?.companyDto?.radiusCompliance
              }
            : '',
          underwriterId:
            underWriters.find(
              (uw) => uw.value === companyData?.companyDto?.underwriterId
            ) || '',
          lossControlId:
            lossControllers.find(
              (lc) => lc.value === companyData?.companyDto?.lossControlId
            ) || '',
          radiusTolerance: companyData?.companyDto?.radiusTolerance || '',
          ...(type === 'edit'
            ? {
                subscriptionTier:
                  companyData?.companyDto?.subscriptionTier || '',
                maintenancePeriod:
                  companyData?.companyDto?.maintenancePeriod >= 0
                    ? {
                        label: companyData?.companyDto?.maintenancePeriod,
                        value: companyData?.companyDto?.maintenancePeriod
                      }
                    : '',
                maintenanceMileage:
                  companyData?.companyDto?.maintenanceMileage || 0,
                zipCode: companyData?.companyDto?.zipCode || '',
                companyPhone: companyData?.companyDto?.companyPhone || '',
                initialTrucks: companyData?.companyDto?.initialTrucks || 0,
                activeTrucks: companyData?.companyDto?.activeTrucks || 0,
                timeZone: companyData?.companyDto?.timeZone || '',
                contactPerson: companyData?.companyDto?.contactPerson || '',
                otherLocationContactPerson:
                  companyData?.companyDto?.otherLocationContactPerson || '',
                otherLocationAddress:
                  companyData?.companyDto?.otherLocationAddress || '',
                otherLocationPhone:
                  companyData?.companyDto?.otherLocationPhone || '',
                website: companyData?.companyDto?.website || '',
                skipVehicleFilter:
                  companyData?.companyDto?.skipVehicleFilter || false,
                assignedVehicleType:
                  companyData?.assignedVehicleType !== null
                    ? {
                        label: companyData?.assignedVehicleType,
                        value: companyData?.assignedVehicleType
                      }
                    : allVehicleTypes[0],
                assignedCycleRules:
                  companyData?.assignedCycleRules !== null
                    ? companyData?.assignedCycleRules &&
                      objectArrayFromSameField(companyData?.assignedCycleRules)
                    : '',
                assignedCycleExceptions:
                  companyData?.assignedCycleExceptions !== null
                    ? companyData?.assignedCycleExceptions &&
                      objectArrayFromSameField(
                        companyData?.assignedCycleExceptions
                      )
                    : '',
                defaultCycleRule: companyData?.defaultCycleRule
                  ? {
                      label: companyData?.defaultCycleRule,
                      value: companyData?.defaultCycleRule
                    }
                  : '',
                showOw: companyData?.companyDto?.showOw || false,
                contractExpirationDate: moment(
                  companyData?.companyDto?.contractExpirationDate
                ).format('L'),
                currentSubscriptionCost: companyData?.companyDto
                  ?.currentSubscriptionCost
                  ? `$${(
                      companyData?.companyDto?.currentSubscriptionCost / 100
                    ).toFixed(2)}`
                  : '$0.00',
                isIftaEnabled: companyData?.companyDto?.isIftaEnabled,
                isDvirEnabled: companyData?.companyDto?.isDvirEnabled,
                clutchSubscriptionPlan:
                  companyData?.companyDto?.clutchSubscriptionPlan
              }
            : {
                firstName: companyData?.companyDto?.firstName || '',
                lastName: companyData?.companyDto?.lastName || '',
                email: companyData?.companyDto?.email || '',
                userPhone: companyData?.companyDto?.userPhone || '',
                password: companyData?.companyDto?.password || ''
              })
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          fields.state = fields.state.value;
          fields.radiusCompliance = fields.radiusCompliance.value;
          fields.lossControlId = fields.lossControlId.value;
          fields.underwriterId = fields.underwriterId.value;
          fields.insuranceExpDate = moment(fields.insuranceExpDate).format(BACKEND_DATE_FORMAT);
          fields.freeTrialEndDate =fields.freeTrialEndDate? moment(fields.freeTrialEndDate).format(BACKEND_DATE_FORMAT):null;
          if (type !== 'edit') {
            fields.initialTrucks = fields?.initialTrucks || null; 
          } else {
            delete fields['initialTrucks'];
          }
          if (type === 'edit') {
            fields.maintenancePeriod = fields?.maintenancePeriod?.value;
            fields.assignedVehicleType =
              fields?.assignedVehicleType?.value || null;
            fields.defaultCycleRule = fields?.defaultCycleRule?.value || null;
            fields.assignedCycleExceptions = fields?.assignedCycleExceptions
              ? fields.assignedCycleExceptions.map((key) => {
                  return key.value;
                })
              : null;
            fields.assignedCycleRules = fields?.assignedCycleRules
              ? fields.assignedCycleRules.map((key) => {
                  return key.value;
                })
              : null;
            if (window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole([ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER])) {
              fields.zohoCustomerId = fields?.zohoId || null;
            }
          }
          delete fields['activeTrucks'];
          delete fields['zohoId'];
          delete fields['timeZone'];
          delete fields['contractExpirationDate'];
          delete fields['currentSubscriptionCost'];
          saveCompanyData({
            apiId,
            data: fields,
            type,
            callback: getTmezonePopup,
            successCallback: () => {
              history.push(pageUrls.fleetCompanies);
            }
          });
        }}
        customProps={{
          hasSaveApiStatus,
          history,
          Styled,
          i18n,
          states,
          cancelSubscription,
          apiStatus,
          callback,
          allCycleRules,
          allCycleExceptions,
          allVehicleTypes,
          updateCard,
          updateCardDetails,
          insurancePortalUser,
          radiusCompliances,
          underWriters,
          lossControllers,
          type
        }}
        handleOnCancel={() => {
          history.push(pageUrls.fleetCompanies);
        }}
        validationSchema={object().shape({
          company: string()
            .required(i18n.t('common.fieldRequiredMessage'))
            .test(
              'len',
              i18n.t('Please enter atleast 4 characters.'),
              (val) => val && val.length > 3
            )
            .test(
              'len',
              i18n.t('Please enter no more than 120 characters.'),
              (val) => val && val.length < 121
            ),
          dotNumber: number()
            .typeError('Please use numbers with maximum length 9')
            .required(i18n.t('common.fieldRequiredMessage')),
          address: string().required(i18n.t('common.fieldRequiredMessage')),
          city: string().required(i18n.t('common.fieldRequiredMessage')),
          state: string().required(i18n.t('common.fieldRequiredMessage')),
          radiusTolerance: number()
          .typeError('Please use numbers')
          .test(
            'is-decimal',
            i18n.t('Please enter only 2 digits after decimal point'),
            value => !(value + "").includes('.') || (value + "").match(/^\d*\.{1}\d{0,2}$/),
          ),
          insuranceExpDate: string().required(i18n.t('common.fieldRequiredMessage')),
          freeTrialEndDate: string()
          .when('autoPaymentEnabled', {
            is: false, // Only required when autoPaymentEnabled is false
            then: string().nullable().required(i18n.t('common.fieldRequiredMessage')),
            otherwise: string().notRequired(),
          }),
          ...(type === 'edit'
            ? {
                zipCode: string().required(
                  i18n.t('common.fieldRequiredMessage')
                ),
                companyPhone: string().required(
                  i18n.t('common.fieldRequiredMessage')
                ),
                ...(window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole([ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER]) ? {
                  zohoId: string().nullable().max(20, i18n.t('common.max20Char')).matches(/^[a-zA-Z0-9]*$/, i18n.t('common.onlyletterNumber'))
                } : {}),
              }
            : {
                firstName: string()
                  .test(
                    'len',
                    i18n.t('common.atleast2Chars'),
                    (val) => val && val.length > 1
                  )
                  .required(i18n.t('common.fieldRequiredMessage')),
                lastName: string()
                  .test(
                    'len',
                    i18n.t('common.atleast2Chars'),
                    (val) => val && val.length > 1
                  )
                  .required(i18n.t('common.fieldRequiredMessage')),
                userPhone: string()
                  .matches(phoneRegExp, i18n.t('common.validNumFormat'))
                  .required(i18n.t('common.fieldRequiredMessage')),
                email: string()
                  .matches(emailRegExp, i18n.t('common.emailValidationMessage'))
                  .required(i18n.t('common.fieldRequiredMessage')),
                password: string().required(
                  i18n.t('common.fieldRequiredMessage')
                ),
                initialTrucks: number().nullable().min(0).required(i18n.t('common.fieldRequiredMessage')),
              }),
          underwriterId: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          lossControlId: string().required(
            i18n.t('common.fieldRequiredMessage')
          )
        })}
      />
      <Modal
        open={addOpen}
        setOpen={setAddOpen}
        data={AddEditModalObject}
        background="black"
        timeZone
        isSuccess
      />
    </>
  );
};
export default CompanyDataForm;

CompanyDataForm.defaultType = {
  hasRegistration: true
};
CompanyDataForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  fetchLogin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  Styled: PropTypes.object.isRequired,
  i18n: PropTypes.array.isRequired,
  hasRegistration: PropTypes.bool
};
