import styled from 'styled-components';
import * as Parent from '../Base/style';
import { getImagePath } from '../../../../utils/utils';

export const ModalWrapper = styled(Parent.ModalWrapper)`
  ${({ theme, width }) => `
  .scheduleForm{
    overflow: auto;
    max-height: 600px;
    .itemWrapper{
      margin-bottom: 10px;
      display: -webkit-inline-box;
      width: 100%;
      .label {
        width: 18%;
      }
      .value {
        font-weight: 600;
      }
    }
    form {
      align-items: center;
    }
    .scheduleDateWrapper{
      width: 50%;
      .fieldWrapper{
        display: block !important;
      }
    }
    padding: 0 60px;
    .fullWidth{
      width:100%;
  }
  .radioWrapper{
      width:100%;
      margin-bottom: 10px !important;
      .label{
          align-items: center;
          display: flex;
          flex: 0 0 18% !important;
          color: ${theme.colors.PRIMARY_CONTRAST};
      }
      .recurrenceIntervalBox {
          display: block !important;
      }
      .infoText{
          color: ${theme.colors.SECONDARY_MAIN};
      }
  }
  .recurrenceIntervalBoxLabel{
      padding-bottom: 25px;;
  }
  }
#modal-title {
  text-align: center;
}
.MuiButtonBase-root .menuItem span:hover {
  color: '#1EA7DB' !important;
}
.MuiButtonBase-root:hover {
  .menuItem span {
    color: ${theme.colors.SECONDARY_MAIN};
  }
}
.departmentConfirm {
  text-align: center;
}
.MuiTouchRipple-root {
  display: none;
}
.MuiButtonBase-root .menuItem span {
  margin-left: 15px;
}
.MuiButtonBase-root .menuItem svg {
  margin-top: 4px;
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root {
  padding-top: 7px;
  padding-bottom: 0px;
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root:last-child {
  padding-bottom: 5px;
}

.MuiButton-root {
  font-size: 16px !important;
  color: '#1EA7DB' !important;
}

.deleteParent {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin-top: 8px;

  .imageSection {
    width: 100%;
    text-align: center;
    img {
      width: 120px;
    }
  }
  .MuiSvgIcon-root {
    fill: #f8bb86;
    width: 110px;
    height: auto;
    margin: 0 auto;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    color: ${theme.colors.MODAL_HEADING};
    font-size: 27px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin-top: 5px;
    margin-bottom: 25px;
    padding: 0;
    line-height: 40px;
    display: block;
    width: 100%;
  }
  p {
    color: ${theme.colors.MODAL_TEXT};
    margin: 0;
  }
  .deletePopupBtn {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
    justify-content: center;
    .deletebtn,
    .cancelBtn {
      margin: 25px 5px 0 5px;
    }
    .cancelBtn #button,
    .deletebtn #button {
      font-size: 23px;
      padding: 0 30px;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        font-size: 20px;
      }
    }
  }
}
.vehicleModal {
  height: auto;
  padding: 0;
  width: ${width ? width : '150px'};
  ul {
    list-style-type: none;
    padding-inline-start: 10px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-left: 0;
  }
  &::before {
    content: '';
    position: absolute;
    width: 0;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 6px solid #181c2d;
    top: -6px;
    right: 5%;
  }
}

#closeBtn .iconContainer {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
  justify-content: flex-end;
}

.driverNameModal {
  ul {
    list-style-type: none;
    padding-inline-start: 10px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-left: 0;
    padding-bottom: 30px;
  }
  .scrollContent{
    .vehicleLink{
      color: #fff;
      text-decoration: none;
      &:hover {
        color: ${theme.colors.LINK_COLOR};
      }
    }
    max-height: 450px;
    overflow: auto;
  }
  li {
    border-bottom: 0.5px solid ${theme.colors.PRIMARY_BORDER};
    padding-bottom: 6px;
  }
  .headWrapper {
    font-size: 18px;
    text-transform: uppercase;
    color: ${theme.colors.LINK_COLOR};
    font-weight: 400;
    text-align: left;
    margin: 15px 0;
  }
}
`}
`;
export const ModalWrapper2 = styled(Parent.ModalWrapper2)`
  ${({ theme }) => `
.connectionHeading {
  font-size: 25px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  letter-spacing: 0.6px;
  margin-top: -5px;
  margin-bottom: 35px;
}
.departmentConfirm {
  text-align: center;
}
.errorSectionlist {
  height: 166px;
  overflow-y: auto;
  overflow: auto;
  .listItem{
    font-size: 14px;
  }
}

.connectionTypeWrapper {
  .active .suggested {
    color: ${theme.colors.WHITE}!important;
  }
  .suggested {
    position: absolute;
    top: 11%;
    color: #fff;
    font-weight: 500;
    color: ${theme.colors.SUGGESTED_COLOR};
    font-size: 13px;
  }
  #shareVideoWrapper .st-btn {
    display:inline-block !important;
  }
  .PopupBtn {
    display: flex;
    justify-content: flex-end;
    padding-top: 37px;
  }
  .cancelBtn {
    padding-right: 14px;
  }
  .cancelBtn #button {
    color: #ffffff !important;
    font-weight: 400;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.3) !important;
    padding: 12px 18px;
    border-radius: 4px;
    border:0;
    min-width: 123px;
    .MuiButton-label {
      opacity: 0.66;
      font-size: 12px;
    }
  }
  .savebtn #button {
    color: #ffffff !important;
    font-weight: 700;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background: #1ea7db !important;
    padding: 12px 18px;
    border-radius: 4px;
    border:0;
    min-width: 123px;
    .MuiButton-label {
      font-size: 12px;
    }
  }
  ul {
    list-style: none;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flexbox;
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: space-between;
    min-width: 800px;
    flex-wrap: wrap;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      min-width: auto;
    }
    .active {
        background: #1ea7db url(${getImagePath(
  'icons/selectedConnection.svg'
)}) no-repeat right top;
        background-position: 97px 9px;
      }
    li {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.04);
      border-radius: 3px;
      padding: 6% 2% 4%;
      margin-right: 12px;
      position: relative;
      min-width: 122px;
      text-align: center;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        min-width: auto;
        margin-right: 3px;
        margin-bottom: 16px;
        flex: 0 0 32%;
      }
      :last-child {
        margin-right: 0;
        img {
          padding-top: 12px;
        }
      }
      :nth-child(3){
        img {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
      :nth-child(5){
        img {
          padding-top: 20px;
          padding-bottom: 8px;
        }
      }
      .listNo {
        padding-top: 10px;
        text-align: center;
        font-weight: 600;
        font-size: 13px;
      }
    }

  }
}
.successpopup {
display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
h2 {
  font-size: 27px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin-top: 5px;
  margin-bottom: 25px;
  padding: 0;
  line-height: 40px;
  display: block;
}
#button {
  margin-top: 20px;
}

label {
padding-top: 15px;
padding-bottom: 15px;
padding-right: 10px;
padding-left: 10px;
text-align: center;
}
}
h2 {
  text-align: center;
}
.imageSection {
width: 100%;
text-align: center;
img {
  width: 120px;
}
}
.deletePopupBtn {
display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
justify-content: center;
.deletebtn,
.cancelBtn {
  margin: 5px 5px 0 5px;
}
.cancelBtn #button,
.deletebtn #button {
  font-size: 23px;
  padding: 0 30px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 14px;
  }
}
.proceedLogin {
  background: #1ea7db;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.6px;
  height: 45px;
  border-radius: 4px;
}
}
#closeBtn .iconContainer {
display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
display: -ms-flexbox;  /* TWEENER - IE 10 */
display: -webkit-flexbox;
display: flex;
justify-content: flex-end;
}
`}
`;
export const ParentWrapper = styled(Parent.ParentWrapper)`
  .modalParentWrapper div:first-child {
    background-color: transparent !important;
  }
`;
