import { isCRMImport, isInsuracePortalUser } from '@/utils/utils';
import {
  crmExportUserTemplateApi,
  crmImportUserApi,
  exportUserApi,
  exportUserTemplateApi,
  importUserApi,
  reserUserPassword,
  userDeleteApi,
  userListApi,
  userListDropdownApi,
  userPaginationListApi,
  userRestoreApi
} from '../../../../config/apis';

const initialState = {
  list: [],
  userList: [],
  filteredList: [],
  count: 0,
  selectedRow: { index: -1, row: {} },
  prevPayload: {}
};

export const users = {
  state: { ...initialState },
  reducers: {
    savePrevPayload(state, payload) {
      return { ...state, prevPayload: payload}
    },
    listSuccess(state, payload) {
      return { ...state, list: [...payload.list], count: payload.count, filteredList: [...payload.list] };
    },
    userListSuccess(state, payload) {
      return { ...state, userList: [...payload] }
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    },
    setSelectedItem(state, data) {
      return { ...state, selectedRow: { ...data } };
    },
    listUpdate(state, list) {
      return {
        ...state,
        list: [...list],
        filteredList: [...list]
      };
    }
  },
  effects: (dispatch) => ({
    async fetchListData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: userListDropdownApi
          },
          id: payload.apiId
        });
        response && this.userListSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchData(payload) {
      try {
        const role = payload?.userRole ? `?userRole=${payload?.userRole}` : '';
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...userListApi,
              url: userListApi.url + role
            }
          },
          id: payload.id
        });
        response && this.listSuccess({ list: response });
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchPaginatedUsers(payload) {
      this.savePrevPayload(payload)
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: userPaginationListApi,
            data: payload.data?.request
          },
          id: payload.apiId
        });
        response && this.listSuccess({ list: response.aaData, count: response.iTotalRecords });
      } catch (error) {
        this.listFailed();
      }
    },
    async handleResetPassword(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...reserUserPassword,
              url: reserUserPassword.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleDeleteAlert(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...userDeleteApi,
              url: userDeleteApi.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        //remove item
        if(response) {
          if(isInsuracePortalUser()) {
            dispatch.users.fetchData({apiId: 'fetchUsers'});
          }else {
            dispatch.users.fetchPaginatedUsers(rootState.users.prevPayload);
          }
        }
        //response && dispatch.users.updateUserListInactive(payload.data.id);
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleRestoreAlert(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...userRestoreApi,
              url: userRestoreApi.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        response && dispatch.users.updateUserListActive(payload.data.id);
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleExportUser(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: exportUserApi
          },
          callback: payload.callback,
          id: payload.id
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleImportUser(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: isCRMImport() ? crmImportUserApi : importUserApi,
            data: payload.data
          },
          callback: payload.callback,
          id: payload.apiId
        });
        if (response?.success && response.success.length > 0) payload.callback('success', 'Data has been imported successfully');
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleExportUserTemplate(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: isCRMImport() ? crmExportUserTemplateApi : exportUserTemplateApi,
          },
          callback: payload.callback,
          id: payload.id
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    updateUserListInactive(id, state) {
      let objIndex = state.users.filteredList.findIndex((obj) => obj.id === id);
      state.users.filteredList[objIndex].status = 'Inactive';
      this.listUpdate(state.users.filteredList);
    },
    updateUserListActive(id, state) {
      let objIndex = state.users.filteredList.findIndex((obj) => obj.id === id);
      state.users.filteredList[objIndex].status = 'Active';
      this.listUpdate(state.users.filteredList);
    }
  })
};
