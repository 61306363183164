export const authApi = {
  url: '/login',
  type: 'post',
  isPublic: true
};

export const testApi = {
  url: 'api/users?page=2',
  type: 'get'
};
export const searchApi = {
  url: 'api/unknown',
  type: 'get'
};

export const cardDetailsApi = {
  url: 'api/users/2',
  type: 'put'
};

export const dutyStatusReportApi = {
  url: 'report/dutyStatus',
  type: 'post'
};
export const driverPerformanceReportApi = {
  url: 'report/driverReport',
  type: 'post'
};
export const editedDataReportApi = {
  url: 'report/editedDataReport',
  type: 'post'
};

export const rejectedEditsApi = {
  url: 'report/rejectedEdits',
  type: 'post'
};

export const drivingEventReportApi = {
  url: 'report/drivingEventReport',
  type: 'post'
};

export const eldDisconnectReportApi = {
  url: 'report/disconnect',
  type: 'post'
};
export const odometerJumpReportApi = {
  url: 'report/odometer',
  type: 'post'
};

export const malfunctionReportApi = {
  url: 'report/malfunctionReport',
  type: 'post'
};

export const billOfLadingReportApi = {
  url: 'report/billOfLadingReport',
  type: 'post'
};

export const unassignedDrivingReportApi = {
  url: 'report/unassignedDriverLogs',
  type: 'post'
};

export const violationReportApi = {
  url: 'report/violationReport',
  type: 'post'
};

export const downloadReportApi = {
  url: 'report/download',
  type: 'post'
};

export const hosViolationsReportApi = {
  url: 'report/hosViolationDetails',
  type: 'post'
};

export const getDriversApi = {
  url: '/user/drivers',
  type: 'get'
};

export const getRegions = {
  url: '/department/listRegions',
  type: 'get'
};

export const getRegionsByCompany = {
  url: '/department/listRegions/{companyId}',
  type: 'get'
}

export const getTerminals = {
  url: '/department/listTerminals/{regionId}',
  type: 'get'
};
export const getDepartments = {
  url: '/department/listDepartments/{terminaId}',
  type: 'get'
};

export const getLocations = {
  url: '/department/listSubDepartments/{departmentId}',
  type: 'get'
};

export const getCompanies = {
  url: 'getCompanies',
  type: 'get'
};

export const getSubscriptions = {
  url: 'getSubscriptions',
  type: 'get'
};

export const saveSubscriptions = {
  url: 'saveSubscriptions',
  type: 'post'
};

export const logoutApi = {
  url: 'web/logout',
  type: 'get',
  isQueryString: false
};
export const forgotPasswordApi = {
  url: 'recovery',
  type: 'post',
  isPublic: true
};

export const manageLogListApi = {
  url: '/logs',
  type: 'get'
};
export const manageUnsignedLogListApi = {
  url: '/logs/unsignedList',
  type: 'get'
}
export const manageArchiveLogListApi = {
  url: '/logs/archives',
  type: 'post'
}

export const dotAuditApi = {
  url: '/logs/fmcsa/sendlogs',
  type: 'post',
  isQueryString: true
};

export const editProfileApi = {
  url: '/user/profile',
  type: 'post'
};

export const getProfileApi = {
  url: '/user/profile',
  type: 'get'
};
export const getCsvStatusHistoryApi = {
  url: '/user/getcsvstatushistory',
  type: 'get'
};
export const getDataResetPasswordApi = {
  url: '/resetPassword',
  type: 'get',
  isQueryString: true,
  isPublic: true
};
export const setDataResetPasswordApi = {
  url: '/resetPassword',
  type: 'post',
  isPublic: true
};

export const logsHistoryApi = {
  url: '/logs/history/',
  type: 'get'
};

export const logBookViewApi = {
  url: '/logs/logbook/{id}',
  type: 'get'
};

export const logBookFilterViewApi = {
  url: '/logs/logbookFilter/{id}/{date}',
  type: 'get'
};

export const regeneratePDFLogBookApi = {
  url: '/logs/create/{driverId}/{startDate}/{endDate}',
  type: 'get'
};

export const logBookStatusApi = {
  url: '/logs/logbookStatus',
  type: 'get',
  isQueryString: true
};
export const dailyLogDownloadApi = {
  url: '/logs/download',
  type: 'post'
};

export const loadMoreEventsApi = {
  url: '/logs/events/{id}',
  type: 'get'
}

export const addLogApi = {
  url: '/logs/addPreviousLog',
  type: 'post',
  isFormData: true
};

export const alertListApi = {
  url: '/alerts/list',
  type: 'get'
};

export const exportAllalertApi = {
  url: '/alerts/exportAlerts',
  type: 'get'
};

export const alertDeleteApi = {
  url: '/alerts/{id}',
  type: 'delete'
};

export const refreshApi = {
  url: '/refresh',
  type: 'post',
  isPublic: true
};

export const udLogListApi = {
  url: 'logs/ud_list',
  type: 'get',
  isQueryString: true
};

export const getUnAssignedEntriesApi = {
  url: 'logs/edit/ud/UNASSIGNED/{id}/entries',
  type: 'get'
};

export const setUnAssignedEntriesApi = {
  url: 'logs/edit/ud/{id}/entries',
  type: 'post'
};

export const updateDailyLogDetailsApi = {
  url: 'logs/edit/',
  type: 'post'
};

export const providerUpdateDailyLogDetailsApi = {
  url: 'logs/provider-edit/',
  type: 'post'
};

export const getDailyLogDetailsApi = {
  url: 'logs/edit/',
  type: 'get'
};

export const updateUdDailyLogDetailsApi = {
  url: 'logs/edit/0/',
  type: 'post'
};

export const getUdDailyLogDetailsApi = {
  url: 'logs/edit/0/',
  type: 'get'
};
export const getUdProfileDailyLogDetailsApi = {
  url: 'logs/edit/1/',
  type: 'get'
};

export const viewDailyLogDetailsApi = {
  url: 'logs/view/',
  type: 'get'
};

export const fetchBreadCrumbForMapApi = {
  url: '/ws/coordinates/breadcrumbHistoryForLogBook',
  type: 'post',
  isQueryString: true
};

export const userListApi = {
  url: 'user/all',
  type: 'get'
};

export const userListDropdownApi = {
  url: 'user/list',
  type: 'get'
};

export const userPaginationListApi = {
  url: 'user/listPaginate',
  type: 'post'
};

export const addUserApi = {
  url: '/user/add',
  type: 'post'
};
export const getUserDataApi = {
  url: '/user/add',
  type: 'get'
};
export const getFeatureFlagsApi = {
  url: '/company/features',
  type: 'get'
};
export const getVehicles = {
  url: '/vehicle/list',
  type: 'get'
};

export const userDeleteApi = {
  url: 'user/delete/{id}',
  type: 'delete'
};

export const reserUserPassword = {
  url: 'user/reset-password?userId={id}',
  type: 'post'
}

export const userRestoreApi = {
  url: 'user/restore/{id}',
  type: 'put'
};

export const importUserApi = {
  url: 'user/import',
  type: 'post'
};

export const crmImportUserApi = {
  url: 'user/mini-import',
  type: 'post'
};

export const exportUserApi = {
  url: 'user/exportUsers',
  type: 'get'
};

export const vehicleListApi = {
  url: 'vehicle/list',
  type: 'get'
};

export const fleetVehiclesApi = {
  url: 'vehicle/list/{id}',
  type: 'get'
};

export const fleetVehiclesRadiusApi = {
  url: 'ws/coordinates/radiusComplianceDates',
  type: 'get'
};

export const vehiclesForRadiusApi = {
  url: 'scorecard/company/:companyId/vehicleListByRadius/:enum',
  type: 'get'
}

export const vehicleDeleteApi = {
  url: 'vehicle/delete/{id}',
  type: 'delete'
};

export const exportVehicleApi = {
  url: 'vehicle/exportVehicles',
  type: 'get'
};

export const importVehicleApi = {
  url: 'vehicle/csvtemplate',
  type: 'get'
};

export const crmImportVehicleApi = {
  url: 'vehicle/mini-import/template',
  type: 'get'
};

export const importTrailerApi = {
  url: 'trailer/csvtemplate',
  type: 'get'
};

export const importVehicleApiPost = {
  url: 'vehicle/import',
  type: 'post'
};

export const crmImportVehicleApiPost = {
  url: 'vehicle/mini-import',
  type: 'post'
};
export const importTrailerApiPost = {
  url: 'trailer/import',
  type: 'post'
};

export const userStateApi = {
  url: '/user/driverStatus/{driverState}',
  type: 'get'
};

export const documentUploadApi = {
  url: 'document/upload',
  type: 'post',
  isFormData: true
};

export const documentDeleteApi = {
  url: 'document/delete/{documentId}',
  type: 'delete'
};

export const trailerDeleteApi = {
  url: '/trailer/delete/{id}',
  type: 'delete'
};

export const exportTrailerApi = {
  url: 'trailer/export-trailers',
  type: 'get'
}

export const vehiclePageMapApi = {
  url: 'ws/coordinates/actual/false',
  type: 'get'
};

export const vehiclePageHistoryMapApi = {
  url: 'ws/coordinates/history',
  type: 'post',
  isQueryString: true
};

export const getEditUserApi = {
  url: '/user/edit/{id}',
  type: 'get'
};

export const editUserApi = {
  url: '/user/edit',
  type: 'post'
};

export const editTrailerApi = {
  url: '/trailer/edit',
  type: 'get'
};

export const editTrailerInsuranceApi = {
  url: '/trailer/update',
  type: 'get'
};

export const addTrailerApi = {
  url: '/trailer/edit',
  type: 'post'
};

export const getEditTrailerApi = {
  url: '/trailer/edit?id={id}',
  type: 'get'
};

export const getEditTrailerInsuranceApi = {
  url: '/trailer/update?id={id}',
  type: 'get'
};

export const editVehicleApi = {
  url: '/vehicle/edit',
  type: 'get'
};

export const editInsuranceVehicleApi = {
  url: '/vehicle/update',
  type: 'get'
};

export const getEditVehicleApi = {
  url: '/vehicle/edit?id={id}',
  type: 'get'
};

export const getInsuranceEditVehicleApi = {
  url: '/vehicle/update?id={id}',
  type: 'get'
};

export const addVehicleApi = {
  url: '/vehicle/edit',
  type: 'post'
};

export const fetchLatLong = {
  url: '/location/getCoordinatesByAddress',
  type: 'get'
};

export const exportUserTemplateApi = {
  url: 'user/csvtemplate',
  type: 'get'
};
export const crmExportUserTemplateApi = {
  url: 'user/mini-import/template',
  type: 'get'
};

export const fetchDailyDocumentsApi = {
  url: 'dailyDocuments/list',
  type: 'get',
  isQueryString: true
};

export const dailyDocumentsUploadApi = {
  url: 'dailyDocuments/upload',
  type: 'post',
  isFormData: true
};

export const fetchCustomPolicyApi = {
  url: 'policy/custom/list',
  type: 'get',
  isQueryString: true
};

export const customPolicyUploadApi = {
  url: 'policy/custom/add',
  type: 'post',
  isFormData: true
};

export const customPolicyDeleteApi = {
  url: 'policy/custom/delete/{id}',
  type: 'delete'
};

export const customPolicyEditApi = {
  url: 'policy/custom/edit',
  type: 'post',
  isFormData: true
};

export const dailyDocumentsDeleteApi = {
  url: 'dailyDocuments/delete/{id}',
  type: 'delete'
};
export const fetchAccidentsApi = {
  url: 'accidents/list',
  type: 'get'
};

export const fetchAccidentsPaginateApi = {
  url: 'accidents/list',
  type: 'post'
};

export const editSchedulingAPI = {
  url: 'manageScheduling/edit',
  type: 'post'
}

export const fetchDownloadCenterListApi = {
  url: 'downloadCenter/list',
  type: 'post'
}

export const fetchSchedulingListApi =  {
  url: `manageScheduling/list`,
  type: 'post'
}

export const deleteReportSchedulingApi = {
  url: '/manageScheduling/{id}',
  type: 'delete'
}

export const fetchReportTypes = {
  url: '/downloadCenter/reportTypes',
  type: 'get'
};

export const potentialViolationsListApi = {
  url: '/violations/print',
  type: 'get'
};

export const exportAllpotentialViolationsApi = {
  url: '/violations/exportViolations',
  type: 'get'
};

export const deletepotentialViolationsApi = {
  url: '/violations/{id}',
  type: 'delete'
};
export const potentialViolationsClearApi = {
  url: '/violations/clearall',
  type: 'get'
};

export const exportCsvViolationApi = {
  url: '/violations/exportViolations',
  type: 'get'
};

export const exportPdfViolationApi = {
  url: '/violations/exportNonDeletedViolations/{docType}',
  type: 'get'
};

export const dashboardApi = {
  url: '/dashboard',
  type: 'get'
};

export const insuranceDashboardApi = {
  url: '/insurance-dashboard',
  type: 'get'
};

export const downloadDocApi = {
  url: '/document/download/{id}',
  type: 'get'
};
export const fetchDocumentsApi = {
  url: '/document/company/documents/edit',
  type: 'get'
};

export const downloadDocumentApi = {
  url: '/document/download/{id}',
  type: 'get'
};

export const viewReportApi = {
  url: '/document/open/{id}',
  type: 'get'
};

export const getFilters = {
  url: '/department/getFilter',
  type: 'get'
};

export const saveFilters = {
  url: '/department/addFilter',
  type: 'post'
};

export const getAccessLevel = {
  url: '/department/getAccessLevel',
  type: 'get'
};

export const monthlyDetailApi = {
  url: '/dashboard/geometris/monthlyDetail',
  type: 'get'
};

export const driverStatusApi = {
  url: '/user/drivers/{driverState}',
  type: 'post'
};
export const driverStatusRefreshApi = {
  url: '/driverStatus/listPaginate',
  type: 'post'
};

export const getDriverNamesApi = {
  url: '/vehicle/findDrivers',
  type: 'get',
  isQueryString: true
};
export const driverStatusHistoryListApi = {
  url: '/driverStatus/history',
  type: 'get',
  isQueryString: true
};

export const exportCsvDriverStatusApi = {
  // url: '/driverStatus/exportViolations',
  // type: 'get'
};

export const exportPdfDriverStatusApi = {
  // url: '/driverStatus/exportNonDeletedViolations/{docType}',
  // type: 'get'
};

export const vehicleNotInUseApi = {
  url: '/vehicle/notInUse/{reason}',
  type: 'get'
};
export const isValidVinVehicleApi = {
  url: 'vehicle/isValidVIN',
  type: 'post',
  isFormData: true
};

export const isValidVinTrailerApi = {
  url: '/trailer/isValidVIN',
  type: 'post',
  isFormData: true
};

export const developerRequestApi = {
  url: '/alerts/requests',
  type: 'get'
};

export const addCompanyApi = {
  url: '/registration/company',
  type: 'post',
  isPublic: true
};

export const getStatesApi = {
  url: '/registration/states',
  type: 'get',
  isPublic: true
};

export const getCompanyApi = {
  url: '/registration/company',
  type: 'get',
  isQueryString: true,
  isPublic: true
};

export const addFleetManagerApi = {
  url: '/registration/fleetmanager',
  type: 'post',
  isPublic: true
};

export const getFleetManagerApi = {
  url: '/registration/fleetmanager',
  type: 'get',
  isQueryString: true,
  isPublic: true
};
export const getTerminalDepartmentLocation = {
  url: '/department/list/{id}',
  type: 'get'
};
export const recentAccidentsClearApi = {
  url: 'accidents/clearall',
  type: 'get'
};
export const vehicleStatusRefreshApi = {
  url: '/vehicleStatus/refresh/{isVehicleStatusPageFlag}',
  type: 'get'
};

export const clearAllAlerts = {
  url: '/alerts/clearall',
  type: 'delete'
};

export const enableNotification = {
  url: '/configuration/update/{id}',
  type: 'get'
};

export const addTruckInfoApi = {
  url: '/registration/trucks',
  type: 'post',
  isPublic: true
};

export const getTruckInfoApi = {
  url: '/registration/trucks',
  type: 'get',
  isQueryString: true,
  isPublic: true
};

export const getTruckTypesApi = {
  url: '/registration/trucktypes',
  type: 'get',
  isPublic: true
};
export const addRegionApi = {
  url: '/department/addRegion',
  type: 'post',
  isFormData: true
};

export const updateRegionApi = {
  url: '/department/editRegion',
  type: 'post'
};

export const regionDeleteApi = {
  url: 'department/deleteRegion/{id}',
  type: 'get'
};

export const addTerminalApi = {
  url: '/department/addTerminal',
  type: 'post',
  isFormData: true
};

export const updateTerminalApi = {
  url: '/department/editTerminal',
  type: 'post'
};

export const terminalDeleteApi = {
  url: 'department/deleteTerminal/{id}',
  type: 'get'
};

export const addDepartmentApi = {
  url: '/department/addDepartment',
  type: 'post',
  isFormData: true
};

export const updateDepartmentApi = {
  url: '/department/editDepartment',
  type: 'post'
};

export const departmentDeleteApi = {
  url: 'department/deleteDepartment/{id}',
  type: 'delete'
};

export const addSubDepartmentApi = {
  url: '/department/addSubDepartments',
  type: 'post'
};

export const updateSubDepartmentApi = {
  url: '/department/editSubDepartment',
  type: 'post'
};

export const subDepartmentDeleteApi = {
  url: 'department/deleteSubDepartment/{id}',
  type: 'get'
};

export const getRegistrationApi = {
  url: '/registration/step1',
  type: 'get',
  isPublic: true
};

export const getPolicyDetailsApi = {
  url: '/policy/edit',
  type: 'get'
};

export const postPolicyDetailsApi = {
  url: '/policy/edit',
  type: 'post'
};

export const getCsaViolationsApi = {
  url: '/csa/violation/list/page',
  type: 'post'
};

export const exportCsaViolationsApi = {
  url: '/csa/violation/download',
  type: 'get'
};

export const getIftaVehiclesApi = {
  url: '/report/mileage',
  type: 'get'
};

export const downloadTruckHistoryApi = {
  url: '/report/getCsvTruckGpsHistory',
  type: 'post',
  isFormData: true
};

export const getIftaMIleageApi = {
  url: '/ws/coordinates/mileage',
  type: 'post',
  isFormData: true
};

export const getIftaAuditApi = {
  url: '/ifta/audit',
  type: 'get'
}

export const getIftaReportApi = {
  url: '/ifta/iftaPdfReport',
  type: 'get'
}

export const listIftaStateApi = {
  url: "/ifta/list",
  type: "post",
}

export const listIftaRefreshApi = {
  url: "/ifta/list",
  type: "post",
}

export const getIftaStateApi = {
  url: "ifta/edit?iftaMileageId={id}",
  type: "get",
}

export const validateOdometer = {
  url: "ifta/validate-odometer",
  type: "post",
}

export const dtaList = {
  url: "/dtc/list",
  type: "post"
}

export const addIftaStateApi = {
  url: "/ifta/add",
  type: "post"
}

export const editIftaStateApi = {
  url: "/ifta/edit",
  type: "put"
}

export const deleteIftaStateApi = {
  url: "/ifta/delete?iftaMileageId={id}",
  type: "delete"
}

export const getRefilsHistoryApi = {
  url: '/fuel/{id}',
  type: 'get'
};

export const getFuelConsumptionApi = {
  url: '/fuel/list',
  type: 'get'
};

export const getModelApi = {
  url: 'registration/model',
  type: 'get',
  isPublic: true
};

export const getMakeApi = {
  url: 'registration/make',
  type: 'get',
  isPublic: true
};

export const addCheckoutApi = {
  url: '/registration/checkout',
  type: 'post',
  isPublic: true
};

export const getCheckoutApi = {
  url: '/registration/checkout',
  type: 'get',
  isQueryString: true,
  isPublic: true
};

export const getAllTrucksApi = {
  url: '/onboarding/trucks',
  type: 'get'
};

export const updateAllTrucksApi = {
  url: '/onboarding/trucks',
  type: 'post'
};

export const isValidVinVehicleApiForClutch = {
  url: '/vehicle/isValidVINClutch',
  type: 'post',
  isQueryString: true
};
export const getUsDotManagementApi = {
  url: '/usdot/management',
  type: 'get'
};

export const addUsDotManagementApi = {
  url: '/usdot/addDotNumber',
  type: 'post'
};

export const editUsDotManagementApi = {
  url: '/usdot/editDotNumber',
  type: 'post'
};

export const deleteUsDotManagementApi = {
  url: '/usdot/delete/{id}',
  type: 'delete'
};

export const sendFeedback = {
  url: '/company/feedback',
  type: 'post'
};

export const driverVehicleListingApi = {
  url: '/inspections/list',
  type: 'get'
};

export const getInspectionsApi = {
  url: '/inspections/list/v1',
  type: 'post'
};

export const getAdvancedConfigApi = {
  url: '/inspections/advancedDvir',
  type: 'get'
};

export const saveAdvancedConfigApi = {
  url: '/inspections/advanceConfig',
  type: 'post'
};
// export const bulkDownloadReportApi = {
//   url: '/violation/download',
//   type: 'post'
// };

export const getPartsApi = {
  url: '/parts/listPaginate',
  type: 'post'
};

export const getWorkOrdersApi = {
  url: '/workorders/list',
  type: 'get'
};
export const driverStatusDashboardApi = {
  url: '/driverStatus',
  type: 'post'
};
export const latestLogId = {
  url: '/logs/latestLog/{id}',
  type: 'get'
}

export const getDocumentTokenApi = {
  url: '/document/token',
  type: 'get'
};

export const getCompanyDataApi = {
  url: '/company/edit',
  type: 'get',
  isQueryString: true
};

export const saveCompanyDataApi = {
  url: '/company/edit',
  type: 'post'
};

export const driverAndFleetCountApi = {
  url: '/dashboard/driverandfleetcount',
  type: 'get'
};

export const connectionTypeApi = {
  url: '/registration/connectiontype',
  type: 'get'
};

export const cancelSubscriptionApi = {
  url: '/subscriptions/cancelsubscriptionclutch',
  type: 'post'
};

export const acceptDevRequestApi = {
  url: '/alerts/accept/{id}',
  type: 'post'
};

export const rejectDevRequestApi = {
  url: '/alerts/{id}',
  type: 'delete'
};

export const updateCardApi = {
  url: '/subscriptions/updatecardclutch',
  type: 'get'
};

export const getSubscriptionApi = {
  url: '/subscriptions/v1/list',
  type: 'get',
  isPublic: true
};

export const getPaymentInformationApi = {
  url: '/payments/list',
  type: 'get'
};
export const upgradeSubscriptionApi = {
  url: 'subscriptions/upgrade',
  type: 'post'
};
export const getAddWorkOrdersApi = {
  url: '/workorders/add',
  type: 'get'
};

export const updateCardDetailsApi = {
  url: '/company/updateCardDetails',
  type: 'post',
  isQueryString: true
};

export const saveAddWorkOrdersApi = {
  url: '/workorders/edit',
  type: 'post'
};

export const editWorkOrdersApi = {
  url: '/workorders/edit/:id',
  type: 'get'
};

export const refreshDriverStatusMap = {
  url: '/driverStatus/more/refresh',
  type: 'post'
};

export const savePartsApi = {
  url: '/parts/add',
  type: 'post'
};

export const updatePartsApi = {
  url: '/parts/edit',
  type: 'post'
};

export const deletePartsApi = {
  url: '/parts/delete/{id}',
  type: 'get'
};

export const driversForChatApi = {
  url: '/chat/getDrivers',
  type: 'get'
};

export const cameraEventsByTotalCount = {
  url: 'vehicle/events-count',
  type: 'post'
};

export const cameraEventsByCount = {
  url: 'vehicle/events-count',
  type: 'post'
};

export const sendBroadCastMessageApi = {
  url: '/chat/sendBroadCastMessage',
  type: 'post'
};

export const createChatTokenApi = {
  url: '/chat/createToken?deviceType=web',
  type: 'get'
};

export const addUsertoChannelApi = {
  url: '/chat/addMemeberToChannel',
  type: 'get',
  isQueryString: true
};

export const fetchTableDataApi = {
  url: '/dashboard/geometris/table/{chart}/detail/{id}/{startDate}/{endDate}?vehicleId={vehicleIdValue}',
  type: 'get'
};

export const fetchChartDataApi = {
  url:
    '/dashboard/geometris/{chart}/detail/{id}/dateRange/{startDate}/{endDate}?vehicleId={vehicleIdValue}',
  type: 'get'
};
export const fetchAlertMessage = {
  url: '/configuration/notificationEnable/{id}',
  type: 'get'
};

export const basicMeasuresApi = {
  url: 'csa/basicMeasures',
  type: 'get'
};

export const crashSummaryApi = {
  url: 'csa/crash/summary',
  type: 'get'
};

export const inspectionSummaryApi = {
  url: 'csa/inspection/summary',
  type: 'get'
};

export const driverDetailsApi = {
  url: 'csa/hybrid',
  type: 'get'
};

export const fuelEconomyApi = {
  url: '/dashboard/geometris/monthlyDetail/fuelEconomy',
  type: 'get'
};

export const vehicleStatusDashboardApi = {
  url: '/vehicleStatus/refresh',
  type: 'get'
};

export const updateSuspendedCardApi = {
  url: '/suspended/change',
  type: 'post',
  isQueryString: true
};

export const driverStatusTableHistoryMapApi = {
  url: '/ws/coordinates/breadcrumbHistory',
  type: 'post',
  isFormData: true
}

export const clearAllDailyLogApi = {
  url: '/logs/clearall',
  type: 'get'
};

export const addTermsCheckApi = {
  url: '/registration/terms',
  type: 'post',
  isQueryString: true,
  isPublic: true
}

export const getCameraUrlApi = {
  url: '/camera/getCameraUrl ',
  type: 'get',
}

//Spireon extra reports
export const dailyHosViolationReportApi = {
  url: '/report/dailyHosViolationReport',
  type: 'post',
}

export const dailyUnsignedLogReportApi = {
  url: '/report/dailyUnsignedLogReport',
  type: 'post',
}
export const dailyFailedInspectionReportApi = {
  url: '/report/dailyFailedInspectionReport',
  type: 'post',
}
export const dailyUnidentifiedDrivingReportApi = {
  url: '/report/dailyUnidentifiedDrivingReport',
  type: 'post',
}
export const getCompaniesOfServiceCenterApi = {
  url: '/company/fleets',
  type: 'get'
};
export const getServiceCenterList = {
  url: '/company/serviceCenter/search/:search',
  type: 'get'
};
export const reAssignServiceCenterApi = {
  url: '/workorders/reAssignServiceCenter',
  type: 'post'
};
export const fetchPendingMechanicInspections = {
  url: '/workorders/list/v1',
  type: 'post'
};
export const radiusComplianceAlertApi = {
  url: '/insurance-dashboard?viewAllAlert=true',
  type: 'get'
}
export const customDvirFormsList = {
  url: '/inspections/customdvir/list',
  type: 'get'
}
export const deleteCustomDvir = {
  url: '/inspections/customdvir/delete',
  type: 'delete',
  isQueryString: true
}
export const enableCustomDvir = {
  url: '/inspections/customdvir/enable',
  type: 'post',
  isQueryString: true,
}
export const getCustomDvirForm = {
  url: 'inspections/customdvir/form',
  type: 'get',
  isQueryString: true,
}
export const postCustomDvirForm = {
  url: 'inspections/customdvir/form',
  type: 'post',
}
export const listAllVehicles = {
  url: 'vehicle/all',
  type: 'get',
}

export const listAllTrailers = {
  url: 'trailer/all  ',
  type: 'get',
}

export const generateMfaQrCodeApi = {
  url: '/generateMfaQrCode',
  type: 'get',
}

export const validateMfaSetupApi = {
  url: '/validateMfaSetup',
  type: 'post',
  isQueryString: true
}

export const modifyMfaSettingsApi = {
  url: '/modifyMfaSettings',
  type: 'post',
  isQueryString: true
}

export const vehicleListDropDownApi = {
  url: '/vehicle/listVehicles',
  type: 'get'
}

export const googleMapMarkerDataUpdate = {
  url: '/location/getLocationFromCoordinates?latitude={latValue}&longitude={lngValue}',
  type: 'post'
}

export const getStateListData = {
  url: 'fuel/edit',
  type: 'get'
}

export const fuelAddEditApi = {
  url: 'fuel/edit',
  type: 'post',
  isFormData: true
}