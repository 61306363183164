import Box from '@material-ui/core/Box';
import lodash from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';

import { AUTH_TOKEN, COOKIE_AUTH_DETAILS, ROLES, imageUrls, pageUrls } from '@/config/constants/keys';
import { getAuthDetails, getImagePath, hasEnabledFeature, hasRestrictedRole, hasValue, isCRMImport, isInsuracePortalUser, objectArrayFromDiffFields } from '@/utils/utils';
import { Link as LinkLi } from '@material-ui/core';
import { Link as LinkDom } from 'react-router-dom';
import { deleteCookie, getCookie } from '../../../services/cookie';
import Filters from './Filters';
import Form from './Form';
import RoadsideInspections from './RoadsideInspections';
import Trailers from './Trailers';
import VideoJS from './videoComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Breadcrumb = lazy(() => import(`../../../components/Breadcrumb`));
const GoogleMap = lazy(() => import(`@/components/GoogleMap/${layout}`));
const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));
const Import = lazy(() => import(`@/components/UI/Import/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const Tab = lazy(() => import(`@/components/UI/Tab/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const Component = ({
  history,
  vehicles,
  apiStatus,
  fetchVehicles,
  handleDeleteAlert,
  callback,
  filterSuccess,
  filterBoth,
  handleExportVehicle,
  handleExportTrailer,
  fetchVehicleRadius,
  handleImportVehicle,
  handleImportTrailer,
  handleImportVehiclePost,
  handleImportTrailerPost,
  fetchVehiclePageHistoryMap,
  vehiclePageMap,
  openDocument,
  getDrivers,
  Styled,
  i18n,
  logBook,
  handleTrailerDeleteAlert,
  pagination,
  setPagination,
  filters,
  newUI,
  fleetCompanies,
  trailerFilterSuccess,
  fetchLiveUrl,
  fetchCameraStatus,
  videoUrl,
  cameraStatus,
  clearFetchLiveUrlTimeout
}) => {
  const beBaseUrl = window?.$environment?.BE_BASE_URL;
  const [openModal, setOpenModal] = useState(false);
  const [openModalRefImport, setModalRefImport] = useState(false);
  const [openModalRefTemplate, setModalRefTemplate] = useState(false);
  const [openModalRefEmptyData, setModalRefEmptyData] = useState(false);
  const [openModalRef2, setModalRef2] = useState(false);
  const [openCameraModal, setCameraModal] = useState(false);
  const [openStatusModal, setStatusModal] = useState(false);
  const [cameraType, setCameraType] = useState(null);
  const [openModalDriverName, setModalDriverName] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const trailerSelected = getCookie('trailerSelected')
  const [value, setValue] = useState(trailerSelected ? 1 : 0);
  const [onRemove, setOnRemove] = useState('false');
  const [cameraVehicleId, setCameraVehicleId] = useState('');
  const [values, setInitialValues] = useState({
    companyId: null,
    unit_id: null
  });

  const setCameraModalFunction = (e) => {
    clearFetchLiveUrlTimeout()
    setCameraModal(false)
  }

  const auth = getAuthDetails();

  useEffect(() => {
    if (videoUrl?.errorMessage) {
      setCameraModal(false);
      setCameraModalList({
        header: '',
        content: () => (
          <video width="720" height="445" id="video" controls></video>
        )
      })
      return;
    }
    if (videoUrl && !(videoUrl?.status == 'live' || videoUrl?.status == 'Live') && !videoUrl?.playUrl) {
      setCameraModalList({
        header: '',
        content: () => (
          <div className="descModal">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ fontWeight: "unset" }} className="headWrapper">{`Unit ID - `}<span style={{ fontWeight: '600' }}>{cameraVehicleId}</span></div>
              <div style={{ fontWeight: "unset", marginRight: "100px" }} className="headWrapper">{`Status - `}<span style={{ fontWeight: '600' }}>{videoUrl?.status}</span></div>
            </div>
            <video width="720" height="445" id="video" controls></video>
          </div>
        )
      })
      return;
    }
    if (videoUrl && videoUrl?.playUrl && (videoUrl?.status == 'live' || videoUrl?.status == 'Live')) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        cameraType,
        aspectRatio: '16:8',
        sources: [{
          src: videoUrl?.playUrl,
          type: 'application/x-mpegURL'
        }]
      }
      setCameraModalList({
        content: () => (
          <div className="descModal">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ fontWeight: "unset" }} className="headWrapper">{`Unit ID - `}<span style={{ fontWeight: '600' }}>{cameraVehicleId}</span></div>
              <div style={{ fontWeight: "unset", marginRight: "100px" }} className="headWrapper">{`Status - `}<span style={{ fontWeight: '600' }}>{videoUrl?.status}</span></div>
            </div>
            <VideoJS options={videoJsOptions} />
          </div>
        )
      });

    }
  }, [videoUrl, videoUrl?.status])
  const cameraStatusColumns = [
    {
      id: 'sn',
      disablePadding: false,
      label: i18n.t('camera.camera_serial_no'),
      needSorting: false
    },
    {
      id: 'cameraStatus',
      disablePadding: false,
      label: i18n.t('camera.cameraStatus'),
      needSorting: false
    },
    {
      id: 'simState',
      disablePadding: false,
      label: i18n.t('camera.simState'),
      needSorting: false
    },
    {
      id: 'lastConnectTime',
      disablePadding: false,
      label: i18n.t('camera.lastConnectTime'),
      needSorting: false
    },
    {
      id: 'lastDisconnectTime',
      disablePadding: false,
      label: i18n.t('camera.lastDisconnectTime'),
      needSorting: false
    },
  ]
  useEffect(() => {
    if (cameraStatus) {
      setModalDescData({
        content: () => {
          return (
            <div className="descModal">
              <div style={{ fontWeight: "unset" }} className="headWrapper">{`Unit ID - `}<span style={{ fontWeight: '600' }}>{cameraVehicleId}</span></div>
              {Object.entries(cameraStatus).length > 0 ?
                <>
                  <div className="headWrapper">{i18n.t('camera.cameraStatus')}</div>
                  <Table
                    type="client"
                    defaultSortBy={{ sortBy: 'date', order: 'desc' }}
                    title=""
                    columns={[...cameraStatusColumns]}
                    data={generateStatusRows(cameraStatus)}
                    emptyMessage={information}
                    id="cameraStatusPopUp"
                    actions={[]}
                    noPagination
                    i18n={i18n}
                  />
                </> :
                <div style={{ textAlign: "center" }} className="headWrapper">No Status available</div>
              }
            </div>
          )
        }
      })
    }
  }, [cameraStatus])
  const generateStatusRows = (data) => {
    data.cameraStatus = data.isOnline ? 'Online' : 'Offline'
    data.lastConnectTime = moment(new Date(data.lastConnectTime)).format(
      'MM/DD/YYYY HH:mm:ss'
    )
    data.lastDisconnectTime = moment(new Date(data.lastDisconnectTime)).format(
      'MM/DD/YYYY HH:mm:ss'
    )
    return [data]
  }
  useEffect(() => {
    deleteCookie('trailerSelected')
  }, [])
  const [driverIdSuggestion, setDriverIdSuggestion] = useState([]);

  const insuracePortalUserRole = isInsuracePortalUser();

  const fleetCompanyList = objectArrayFromDiffFields(
    fleetCompanies,
    'id',
    'name',
  );

  useEffect(() => {
    if (vehicles.vehicleList.length && !insuracePortalUserRole) driverArray(vehicles.vehicleList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles]);
  useEffect(() => {
    document.addEventListener('click', (event) => {
      if (
        event?.target?.parentElement?.parentElement?.id !== 'addVehiclesTarget'
      ) {
        let dv = document.getElementById('addUsers');
        if (dv) dv.style.display = 'none';
      }

      if (
        event?.target?.parentElement?.parentElement?.id !==
        'importUserTemplateContainer'
      ) {
        let dv = document.getElementById('importItems');
        if (dv) dv.style.display = 'none';
      }
      if (
        event?.target?.parentElement?.parentElement?.id !==
        'importUserContainer'
      ) {
        let dv = document.getElementById('ImportVehicles');
        if (dv) dv.style.display = 'none';
      }
      if (
        event?.target?.parentElement?.parentElement?.id !==
        'exportContainer'
      ) {
        let dv = document.getElementById('ExportVehicles');
        if (dv) dv.style.display = 'none';
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      vehiclePageMap.type === 'vehicleHistoryMap' &&
      vehiclePageMap.data &&
      vehiclePageMap.data.length === 0
    ) {
      clickedModalEmptyData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclePageMap]);
  const driverArray = (source) => {
    setDriverIdSuggestion(source.map((items) => ({
      label: items.unit_id,
      value: items.id
    })));
  };
  const selectChange = (e) => {
    setOnRemove('false');
    setInitialValues({
      companyId: null,
      unit_id: null
    })
    setPagination(true);
    filterBoth(e);
  };
  const closeModal = (event) => {
    setOpenModal(false);
  };

  const closeModalDelete = (event) => {
    setModalRef2(false);
  };
  const clickedModalDelete = (e) => {
    setModalRef2(true);
  };
  const clickedModalCamera = (e) => {
    setCameraModal(true)
  }
  const closeModalImport = (event) => {
    setModalRefImport(false);
  };

  const closeModalTemplate = (event) => {
    setModalRefTemplate(false);
    let dv = document.getElementById('importItems');
    if (dv.style.display === 'block') {
      dv.style.display = 'none';
    } else {
      dv.style.display = 'block';
    }
  };

  const closeModalEmptyData = (event) => {
    setModalRefEmptyData(false);
  };
  const clickedModalEmptyData = (e) => {
    setModalRefEmptyData(true);
  };

  const [ModalListObject] = useState({
    header: '',
    content: () => { }
  });
  const [ModalListObject2, setModalListObject2] = useState({
    header: '',
    content: () => { }
  });
  const [cameraModalList, setCameraModalList] = useState({
    header: '',
    content: () => (
      <video width="720" height="445" id="video" controls></video>
    )
  });
  const [ModalDescData, setModalDescData] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });
  const [ModalListObjectImport] = useState({
    header: '',
    content: () => { }
  });
  const [ModalListObjectTemplate] = useState({
    header: '',
    content: () => { }
  });
  const [ModalDriverNameData, setModalDriverNameData] = useState({
    header: '',
    content: () => {
      return <> </>;
    }
  });

  const [ModalMessageObject, setModalMessageObject] = useState({
    header: '',
    content: () => { }
  });

  const [ModalListObjectEmptyData] = useState({
    header: '',
    content: () => (
      <div className="deleteParent">
        <div className="imageSection">
          <img src={getImagePath(imageUrls.confirmIcon)} alt="Are you sure" />
        </div>
        <h2> {i18n.t('common.noBreadcrumbData')} </h2>
        <div className="deletePopupBtn">
          <div className="deletebtn">
            <Button
              label={i18n.t('common.ok')}
              type={'submit'}
              onClick={() => {
                closeModalEmptyData();
              }}
            ></Button>
          </div>
        </div>
      </div>
    )
  });
  const exportVehicle = () => {
    handleExportVehicle({
      apiId: 'exportVehicle',
      callback: callback
    });
  };
  const exportTrailer = () => {
    handleExportTrailer({
      apiId: 'exportTrailer',
      callback: callback
    });
  };
  const checkCamera = () => {
    let flag = false
    vehicles.vehicleFilteredList.forEach(ele => {
      if (ele.cameraSerialNo && ele.cameraSerialNo.length > 0) flag = true
    })
    return flag
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const columns = [
    ...(insuracePortalUserRole ?
      [
        {
          id: 'companyName',
          numeric: false,
          disablePadding: true,
          label: i18n.t('policyHolder'),
          needSorting: true
        }] : []),
    {
      id: 'unit_id',
      numeric: false,
      disablePadding: true,
      label: i18n.t('vehicles.unitId'),
      needSorting: true
    },
    {
      id: 'driversList',
      numeric: true,
      disablePadding: false,
      label: i18n.t('vehicles.drivers'),
      needSorting: true
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.type'),
      needSorting: true
    },
    {
      id: 'vin',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.vin'),
      needSorting: true
    },
    {
      id: 'year',
      numeric: true,
      disablePadding: false,
      label: i18n.t('vehicles.year'),
      needSorting: true
    },
    {
      id: 'manufact',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.manufacturer'),
      needSorting: true
    },
    {
      id: 'model',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.model'),
      needSorting: true
    },
    ...(!(window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER'])) || (checkCamera() && hasEnabledFeature('cameraIntegrationEnabled')) ? [
      {
        id: 'actions',
        label: i18n.t('Actions')
      }
    ] : [])
  ];
  const generateRows = () => {
    const updatedList = vehicles.vehicleFilteredList.map((item, index) => {
      item.driversList = (
        <div
          className="driverNamePopup"
          onClick={() => openDriverNamePopup(item.drivers, item.unit_id)}
        >
          {item.driversCount}
        </div>
      );
      return item;
    });
    return updatedList;
  };

  const filterVehicleByCompany = (query) => {
    setOnRemove('clear');
    const filteredList = lodash.filter(vehicles.vehicleList, query);
    filterSuccess(filteredList)
  }

  const filterTrailerByCompany = (query) => {
    const filteredList = lodash.filter(vehicles.trailerList, query);
    trailerFilterSuccess(filteredList)
  }

  const resetVehicleList = () => {
    filterSuccess(vehicles.vehicleList)
  }

  const resetTrailerList = () => {
    trailerFilterSuccess(vehicles.trailerList)
  }

  const getLiveStreamingUrl = (cameraSerialNo) => {
    fetchLiveUrl({
      apiId: 'liveStreamingApi',
      cameraSerialNo: cameraSerialNo,
      callback: callback,
    })
  }
  const getCameraStatus = (cameraSerialNo) => {
    fetchCameraStatus({
      apiId: 'cameraStatusApi',
      cameraSerialNo
    })
  }

  const openDriverNamePopup = (drivers, unit_id) => {
    // setDriver(unit_id);
    getDrivers({
      apiId: 'getDriversVehiclePage',
      data: {
        driverIds: drivers
      },
      callback: openDriverNamePopupModal,
      unit_id: unit_id
    });
  };
  const openDriverNamePopupModal = (type, res, req) => {
    let str = res?.response;
    let str_array = str.split(',');
    let array = [];
    for (var i = 0; i < str_array.length; i++) {
      array.push(str_array[i]);
    }
    // clickedModalDriverName();
    setModalDriverName(true);
    return setModalDriverNameData({
      content: () => {
        return (
          <div className="driverNameModal">
            <div className="headWrapper">{i18n.t('DRIVERS OF')} {req?.unit_id}</div>
            <ul>
              {array.map((items, index) => {
                return <li key={index}>{items}</li>;
              })}
            </ul>
          </div>
        );
      }
    });
  };
  const hasUserListApiStatus = hasValue(apiStatus.status, 'api', fetchVehicles);
  const information =
    hasUserListApiStatus && hasUserListApiStatus.errorMessage ? (
      <Message
        type="error"
        message={i18n.t(hasUserListApiStatus.errorMessage)}
      />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );
  const tabValues = [
    {
      name: i18n.t('vehicles.tabs.listVehicles'),
      value: (
        <Styled.TableWrapper>
          {insuracePortalUserRole && (
            <Filters
              fleetCompanyList={fleetCompanyList}
              filterList={(data) => {
                setPagination(true)
                filterVehicleByCompany(data)
              }}
              handleReset={resetVehicleList}
              values={values}
              setInitialValues={setInitialValues}
            />)
          }
          <Table
            type="client"
            defaultSortBy={{ sortBy: 'unit_id', order: 'asc' }}
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={10}
            actions={window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER']) ? [
              {
                icon: (row, index) => {
                  return (
                    <LinkLi
                      component={LinkDom}
                      to={pageUrls.editVehicle.replace(':id', row.id)}
                      className="normalLink"
                    >
                      <Icon tooltip={i18n.t('common.edit')} src={getImagePath(imageUrls.edit)} />
                    </LinkLi>
                  );
                }
              },
              ...(hasEnabledFeature('cameraIntegrationEnabled') ? [{
                icon: (row, index) => {
                  return (
                    checkCamera() ? <div style={{ display: row.cameraSerialNo ? '' : 'none' }}><Icon
                      tooltip="Live stream"
                      src={getImagePath(imageUrls.cameraIcon)}
                      onClick={() => {
                        setCameraVehicleId(row.unit_id)
                        getLiveStreamingUrl(row.cameraSerialNo)
                        setCameraType(row?.cameraType);
                        setCameraModalList({
                          content: () => (
                            <div className="descModal">
                              <div style={{ fontWeight: "unset" }} className="headWrapper">{`Unit ID - `}<span style={{ fontWeight: '600' }}>{row.unit_id}</span></div>
                              <video width="720" height="445" id="video" controls></video>
                            </div>
                          )
                        });
                        clickedModalCamera();
                      }}
                    /></div> : null
                  );
                }
              },
              {
                icon: (row, index) => {
                  return (
                    checkCamera() ? <div style={{ display: row.cameraSerialNo ? '' : 'none' }}><Icon
                      src={getImagePath(imageUrls.viewStatus)}
                      tooltip="Status"
                      onClick={() => {
                        setCameraVehicleId(row.unit_id)
                        getCameraStatus(row.cameraSerialNo)
                        setModalDescData({
                          content: () => (
                            <div className="descModal">
                              <div style={{ fontWeight: "unset" }} className="headWrapper">{`Unit ID - `}<span style={{ fontWeight: '600' }}>{row.unit_id}</span></div>
                              <div style={{ textAlign: "center" }} className="headWrapper">No Status available</div>
                            </div>
                          )
                        });
                        setStatusModal(true)
                      }}
                    /></div> : null
                  );
                }
              }] : [])
            ] : [
              {
                icon: (row, index) => {
                  return (
                    <LinkLi
                      component={LinkDom}
                      to={pageUrls.editVehicle.replace(':id', row.id)}
                      className="normalLink"
                    >
                      <Icon tooltip={i18n.t('common.edit')} src={getImagePath(imageUrls.edit)} />
                    </LinkLi>
                  );
                }
              },
              ...(window?.$environment?.SERVICE_PROVIDER !== 'webfleet' ? [{
                icon: (row, index) => {
                  return (
                    <Icon
                      tooltip={i18n.t('common.delete')}
                      src={getImagePath(imageUrls.delete)}
                      onClick={() => {
                        setModalListObject2({
                          content: () => (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2> {i18n.t('common.sureConfirm')} </h2>
                              <p> {i18n.t('common.recoverConfirm')}</p>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('Cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      closeModalDelete();
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.deletePopup')}
                                    onClick={() => {
                                      handleDeleteAlert({
                                        id: 'deleteAlert',
                                        data: { id: row.id },
                                        callback: callback
                                      });
                                      closeModalDelete();
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        clickedModalDelete();
                      }}
                    />
                  );
                }
              },
              ...(hasEnabledFeature('cameraIntegrationEnabled') || insuracePortalUserRole ? [{
                icon: (row, index) => {
                  return (
                    checkCamera() ? <div style={{ display: row.cameraSerialNo ? '' : 'none' }}><Icon
                      tooltip={i18n.t('common.liveCamera')}
                      src={getImagePath(imageUrls.cameraIcon)}
                      onClick={() => {
                        setCameraVehicleId(row.unit_id)
                        getLiveStreamingUrl(row.cameraSerialNo);
                        setCameraType(row?.cameraType);
                        setCameraModalList({
                          content: () => (
                            <div className="descModal">
                              <div style={{ fontWeight: "unset" }} className="headWrapper">{`Unit ID - `}<span style={{ fontWeight: '600' }}>{row.unit_id}</span></div>
                              <video width="720" height="445" id="video" controls></video>
                            </div>
                          )
                        });
                        clickedModalCamera(true);
                      }}
                    /></div> : null
                  );
                }
              },
              {
                icon: (row, index) => {
                  return (
                    checkCamera() ? <div style={{ display: row.cameraSerialNo ? '' : 'none' }}><Icon
                      tooltip={i18n.t('common.cameraStatus')}
                      src={getImagePath(imageUrls.viewStatus)}
                      onClick={() => {
                        setCameraVehicleId(row.unit_id)
                        getCameraStatus(row.cameraSerialNo)
                        setModalDescData({
                          content: () => (
                            <div className="descModal">
                              <div style={{ fontWeight: "unset" }} className="headWrapper">{`Unit ID - `}<span style={{ fontWeight: '600' }}>{row.unit_id}</span></div>
                              <div style={{ textAlign: "center" }} className="headWrapper">No Status available</div>
                            </div>
                          )
                        });
                        setStatusModal(true)
                      }}
                    /></div> : null
                  );
                }
              }] : [])
              ] : [])
            ]}
            defaultSort={'full_name'}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
      )
    },
    {
      name: i18n.t('vehicles.tabs.listTrailers'),
      value: (
        <Trailers
          history={history}
          callback={callback}
          trailers={vehicles?.trailerFilteredList}
          apiStatus={apiStatus}
          handleDeleteAlert={handleTrailerDeleteAlert}
          fleetCompanyList={fleetCompanyList}
          insuracePortalUserRole={insuracePortalUserRole}
          filterList={filterTrailerByCompany}
          handleReset={resetTrailerList}
        />
      )
    },
    ...(window?.$environment?.SERVICE_PROVIDER !== 'webfleet' && window?.$environment?.SERVICE_PROVIDER !== 'riskTheory' && !insuracePortalUserRole ? [
      {
        name: i18n.t('vehicles.tabs.roadInspection'),
        value: (
          <RoadsideInspections
            history={history}
            openDocument={openDocument}
            i18n={i18n}
          />
        )
      }
    ] : [])
  ];
  const addVehicle = () => {
    let dv = document.getElementById('addUsers');
    if (dv.style.display === 'block') {
      dv.style.display = 'none';
    } else {
      dv.style.display = 'block';
    }
  };
  const exportVehicleTemplate = () => {
    let dv = document.getElementById('importItems');
    if (dv.style.display === 'block') {
      dv.style.display = 'none';
    } else {
      dv.style.display = 'block';
    }
  };
  const importVehicles = (file) => {
    handleImportVehiclePost({
      data: file,
      apiId: 'importVehiclePost',
      callback: callback
    });
    closeModalImport();
    if (document.getElementById('fileSelectimportVeh'))
      document.getElementById('fileSelectimportVeh').value = '';
  };
  const importTrailers = (file) => {
    handleImportTrailerPost({
      data: file,
      apiId: 'importTrailerPost',
      callback: callback
    });
    closeModalImport();
    if (document.getElementById('fileSelectimportTra'))
      document.getElementById('fileSelectimportTra').value = '';
  };
  const importVehicle = (file) => {
    let dv = document.getElementById('ImportVehicles');
    if (dv.style.display === 'block') {
      dv.style.display = 'none';
    } else {
      dv.style.display = 'block';
    }
  };

  const handleExport = (file) => {
    let dv = document.getElementById('ExportVehicles');
    if (dv.style.display === 'block') {
      dv.style.display = 'none';
    } else {
      dv.style.display = 'block';
    }
  };
  return (
    <>
      <Styled.Wrapper>
        <Styled.TopWrapper>
          <Styled.SearchWrapper>
            <Search
              onChange={(e) => selectChange(e)}
              default={{vehicleFilteredList: vehicles.vehicleList, trailerFilteredList:vehicles?.trailerList}}
              fromData
              placeHolder={i18n.t('vehicles.searchVehicleTrailer')}
              query={['unit_id', 'vin', 'driverNames', 'manufact']}
              id='searchVehicle'
              onRemove={onRemove}
            ></Search>
            {window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER']) ? null :
              <Icon
                src={getImagePath(imageUrls.addVehicle)}
                onClick={() => addVehicle()}
                tooltip={i18n.t('common.add')}
                id="addVehiclesTarget"
              />
            }
            <div id="addUsers" className="vehicleModal">
              <ul>
                {window?.$environment?.SERVICE_PROVIDER !== 'webfleet' &&
                  (
                    <li>
                      <LinkLi
                        component={LinkDom}
                        variant="body2"
                        to={pageUrls.addVehicle}
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        {i18n.t('vehicles.addVehicle')}
                      </LinkLi>
                    </li>
                  )
                }
                <li>
                  <LinkLi
                    component={LinkDom}
                    variant="body2"
                    to={pageUrls.addTrailer}
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    {i18n.t('vehicles.addTrailer')}
                  </LinkLi>
                </li>
              </ul>
            </div>
          </Styled.SearchWrapper>
          <Styled.ImpExpWrapper>
            {window?.$environment?.SERVICE_PROVIDER !== 'webfleet' && !(window?.$environment?.SERVICE_PROVIDER == 'riskTheory' && hasRestrictedRole(['FLMANAGER'])) &&
              (<Export
                src={imageUrls.importUserTemplate}
                filename={i18n.t('common.template')}
                toolTip={i18n.t('common.template')}
                className="importUserTemplateContainer"
                onClick={() => exportVehicleTemplate()}
                buttonName={i18n.t('common.template')}
                id="importUserTemplateContainer"
                newUI={newUI}
              />)}
            <div id="importItems" className="vehicleModal">
              <ul>
                <li>
                  <Link
                    variant="body2"
                    onClick={() => {
                      let url = isCRMImport() ?  beBaseUrl + '/vehicle/mini-import/template'
                        : beBaseUrl +
                        '/vehicle/csvtemplate' +
                        '?token=' +
                        logBook?.documentToken;
                      let filename = 'Vehicle_import_template.csv';
                      if (isCRMImport()) {
                        filename = 'Vehicle_mini_import_template.csv';
                      }
                      if ((!isCRMImport() )&& hasEnabledFeature('cameraIntegrationEnabled')) {
                        filename = 'Camera_Integrated_Import_Data_vehicle.csv';
                      }
                        const iframe = document.getElementById('importVehicleTemplateFrame');
                        const token = getCookie(COOKIE_AUTH_DETAILS)[AUTH_TOKEN];
                        iframe.contentWindow.fetch(url, {
                            headers: {
                              "Authorization": 'Bearer ' + token,
                            }
                        }).then(response => {
                            if(response.ok) {
                                response.blob().then(blob => {
                                    let url = window.URL.createObjectURL(blob);
                                    let a = document.createElement('a');
                                    a.href = url;
                                    a.download = filename;
                                    a.click();
                                });
                            } else {
                                console.log('Error: ' + response.status);
                            }
                        }).catch(error => {
                            console.log(error);
                        });
                   
                      closeModalTemplate();
                    }}
                  >
                    {i18n.t('vehicles.importVehicleTemplate')}
                  </Link>
                </li>
                <li>
                  <Link
                    variant="body2"
                    onClick={() => {
                      let url =
                        beBaseUrl +
                        '/trailer/csvtemplate' +
                        '?token=' +
                        logBook?.documentToken;

                      document.getElementById(
                        'importVehicleTemplateFrame'
                      ).src = url;
                      closeModalTemplate();
                    }}
                  >
                    {i18n.t('vehicles.importTrailerTemplate')}
                  </Link>
                </li>
              </ul>
            </div>
            {window?.$environment?.SERVICE_PROVIDER !== 'webfleet' && !(window?.$environment?.SERVICE_PROVIDER == 'riskTheory' && hasRestrictedRole(['FLMANAGER'])) &&
              (<Export
                src={imageUrls.import}
                className="importUserContainer"
                toolTip={i18n.t('common.import')}
                onClick={(e) => importVehicle(e)}
                buttonName={i18n.t('common.import')}
                id="importUserContainer"
                newUI={newUI}
              />)}
            <div id="ImportVehicles" className="vehicleModal">
              <ul>
                <li>
                  <Import
                    onClick={(e) => {
                      if (window?.$environment?.SERVICE_PROVIDER === 'riskTheory') {
                        setModalMessageObject({
                          content: () => (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2>
                                {i18n.t('common.insurancePortalWarning')}
                              </h2>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('common.ok')}
                                    type={'reset'}
                                    onClick={() => {
                                      setOpenMessageModal(false);
                                      if (document.getElementById('fileSelectimportVeh'))
                                        document.getElementById('fileSelectimportVeh').value = '';
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.proceedToImport')}
                                    onClick={() => {
                                      setOpenMessageModal(false);
                                      importVehicles(e)
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        setOpenMessageModal(true);
                      } else {
                        importVehicles(e)
                      }
                    }}
                    asBinary
                    importVehicle={i18n.t('vehicles.importVeh')}
                    id="importVeh"
                  />
                </li>
                <li>
                  <Import
                    onClick={(e) => {
                      if (window?.$environment?.SERVICE_PROVIDER === 'riskTheory') {
                        setModalMessageObject({
                          content: () => (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2>
                                {i18n.t('common.insurancePortalWarning')}
                              </h2>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('common.ok')}
                                    type={'reset'}
                                    onClick={() => {
                                      setOpenMessageModal(false);
                                      if (document.getElementById('fileSelectimportTra'))
                                        document.getElementById('fileSelectimportTra').value = '';
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.proceedToImport')}
                                    onClick={() => {
                                      setOpenMessageModal(false);
                                      importTrailers(e)
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        setOpenMessageModal(true);
                      } else {
                        importTrailers(e)
                      }
                    }}
                    asBinary
                    importVehicle={i18n.t('vehicles.importTra')}
                    id="importTra"
                  />
                </li>
              </ul>
            </div>

            <Export
              src={imageUrls.exportIcon}
              className="importUserContainer"
              toolTip={i18n.t('common.export')}
              onClick={(e) => handleExport(e)}
              buttonName={i18n.t('common.export')}
              id="exportContainer"
              newUI={newUI}
            />
            <div id="ExportVehicles" className="vehicleModal">
              <ul>
                <li>
                  <div
                    className={'exportLink'}
                    onClick={() => exportVehicle()}
                  >{i18n.t('vehicles.exportVehicle')}</div>
                </li>
                <li>
                  <div
                    className={'exportLink'}
                    onClick={() => exportTrailer()}
                  >{i18n.t('vehicles.exportTrailer')}</div>
                </li>
              </ul>
            </div>
          </Styled.ImpExpWrapper>
          {layout === 'OldUi' && <Breadcrumb filters={filters} />}
        </Styled.TopWrapper>
        {window.$environment?.SERVICE_PROVIDER !== 'cyntrx' &&
          window.$environment?.SERVICE_PROVIDER !== 'edvir' &&
          window.$environment?.SERVICE_PROVIDER !== 'webfleet' &&
          !hasRestrictedRole([ROLES.SERVICE_MANAGER]) &&
          <Styled.MiddleWrapper>
            <Styled.MapWrapper>
              <GoogleMap
                type={vehiclePageMap.type}
                height={insuracePortalUserRole ? '550px' : '450px'}
                data={vehiclePageMap}
              />
            </Styled.MapWrapper>
            <Styled.TrackWrapper>
              <div className="trackVehicleForm">
                <Form
                  apiStatus={apiStatus}
                  fetchVehicleRadius={fetchVehicleRadius}
                  fetchVehiclePageHistoryMap={fetchVehiclePageHistoryMap}
                  history={history}
                  vehicles={vehicles}
                  driverIdSuggestion={driverIdSuggestion}
                  Styled={Styled}
                  fleetCompanyList={fleetCompanyList}
                  insuracePortalUserRole={insuracePortalUserRole}
                  driverArray={driverArray}
                />
              </div>
            </Styled.TrackWrapper>
          </Styled.MiddleWrapper>}
        {/* {window?.$environment?.SERVICE_PROVIDER !== 'webfleet'?  */}
        {/* ( */}
        <Styled.BottomWrapper>
          <Tab
            data={tabValues}
            currentTab={value}
            onChange={handleChange}
            className="vehicleTabs"
          />
        </Styled.BottomWrapper>
        {/* ):
        (<Styled.TableWrapper>
          <Table
            type="client"
            defaultSortBy={{ sortBy: 'unit_id', order: 'asc' }}
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={10}
            actions={[
              {
                icon: (row, index) => {
                  return (
                    <LinkLi
                      component={LinkDom}
                      to={pageUrls.editVehicle.replace(':id', row.id)}
                      className="normalLink"
                    >
                      <Icon src={getImagePath(imageUrls.edit)} />
                    </LinkLi>
                  );
                }
              },
              {
                icon: (row, index) => {
                  return (
                    <Icon
                      src={getImagePath(imageUrls.delete)}
                      onClick={() => {
                        setModalListObject2({
                          content: () => (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2> {i18n.t('common.sureConfirm')} </h2>
                              <p> {i18n.t('common.recoverConfirm')}</p>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('Cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      closeModalDelete();
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.deletePopup')}
                                    onClick={() => {
                                      handleDeleteAlert({
                                        id: 'deleteAlert',
                                        data: { id: row.id },
                                        callback: callback
                                      });
                                      closeModalDelete();
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        clickedModalDelete();
                      }}
                    />
                  );
                }
              }
            ]}
            defaultSort={'full_name'}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>)
        } */}
      </Styled.Wrapper>

      <Modal
        open={openModal}
        setOpen={setOpenModal}
        data={ModalListObject}
        isUserProfile
        optionListPopup
        top={166}
        right={13}
      />

      <Modal
        open={openCameraModal}
        setOpen={setCameraModalFunction}
        data={cameraModalList}
        width={'760px'}
        withCloseIcon
        alignItemsLeft
        removeTopSpace
      />
      <Modal
        open={openStatusModal}
        setOpen={setStatusModal}
        data={ModalDescData}
        withCloseIcon
        width={'800px'}
        alignItemsLeft
        removeTopSpace
      />
      <Modal
        open={openModalRef2}
        setOpen={setModalRef2}
        data={ModalListObject2}
        deletePopup
      />
      <Modal
        open={openModalRefTemplate}
        setOpen={setModalRefTemplate}
        data={ModalListObjectTemplate}
        isUserProfile
        optionListPopup
        width={'200px'}
        right={10}
      />
      <Modal
        open={openModalRefImport}
        setOpen={setModalRefImport}
        data={ModalListObjectImport}
        isUserProfile
        optionListPopup
        right={7}
      />
      <Modal
        open={openModalRefEmptyData}
        setOpen={setModalRefEmptyData}
        data={ModalListObjectEmptyData}
        deletePopup
        width={'450px'}
      />
      <Modal
        open={openModalDriverName}
        setOpen={setModalDriverName}
        data={ModalDriverNameData}
        deletePopup
        withCloseIcon
        alignItemsLeft
        width={'260px'}
      />
      <Modal open={openMessageModal} setOpen={setOpenMessageModal} data={ModalMessageObject} deletePopup />
      <iframe
        id="importVehicleTemplateFrame"
        style={{ display: 'none' }}
      ></iframe>
    </>
  );
};

Component.propTypes = {
  callback: PropTypes.func.isRequired,
  vehicles: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired,
  filterBoth: PropTypes.func.isRequired,
  handleExportVehicle: PropTypes.func.isRequired,
  handleExportTrailer: PropTypes.func.isRequired,
  handleImportVehicle: PropTypes.func.isRequired,
  handleImportTrailer: PropTypes.func.isRequired,
  handleImportVehiclePost: PropTypes.func.isRequired,
  handleImportTrailerPost: PropTypes.func.isRequired,
  fetchVehiclePageMap: PropTypes.func.isRequired,
  fetchVehiclePageHistoryMap: PropTypes.func.isRequired,
  vehiclePageMap: PropTypes.func.isRequired
};
