import React, { lazy, useState } from 'react';

import { AUTH_TOKEN, COOKIE_AUTH_DETAILS, ROLES, imageUrls, pageUrls } from '@/config/constants/keys';
import { getCookie } from '@/services/cookie';
import { getImagePath, hasRestrictedRole, isCRMImport, isInsuracePortalUser } from '@/utils/utils';
import { Link as LinkLi } from '@material-ui/core';
import { Link } from 'react-router-dom';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));
const Import = lazy(() => import(`@/components/UI/Import/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Breadcrumb = lazy(() => import(`@/components/Breadcrumb`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es';

const TopActions = ({
  users,
  callback,
  filterSuccess,
  handleExportVehicle,
  handleExportUser,
  handleImportUser,
  Styled,
  i18n,
  logBook,
  setPagination,
  filterButtons,
  setButtons,
  history,
  filters,
  removeData,
  setremoveData,
  viewExportOptions,
  isMechanicUser,
  newUI
}) => {
  const beBaseUrl = window?.$environment?.BE_BASE_URL;
  const [ModalMessageObject, setModalMessageObject] = useState({
    header: '',
    content: () => { }
  });
  const [openMessageModal, setOpenMessageModal] = useState(false);

  const selectChange = (e) => {
    filterSuccess(e);
    setPagination(true);
    setButtons(
      filterButtons.length &&
        filterButtons.map((key, i) => {
          if (i === 0) return { ...key, selected: true };
          else return { ...key, selected: false };
        })
    );
    setremoveData('false')
  };
  const exportUser = () => {
    handleExportUser({
      id: 'exportUser'
    });
  };
  const importUser = (file) => {
    handleImportUser({
      data: file,
      apiId: 'importUser',
      callback: callback
    });
    if (document.getElementById('fileSelectimportVeh'))
      document.getElementById('fileSelectimportVeh').value = '';
    if (document.getElementById('fileSelect'))
      document.getElementById('fileSelect').value = '';
  };
  const exportUserTemplate = () => {
    let url = isCRMImport() ? beBaseUrl + '/user/mini-import/template' : beBaseUrl + '/user/csvtemplate' + '?token=' + logBook?.documentToken;

      const iframe = document.getElementById('importUserTemplateFrame');
      const token = getCookie(COOKIE_AUTH_DETAILS)[AUTH_TOKEN];
      iframe.contentWindow.fetch(url, {
          headers: {
            'Accept-Language': isEs() ? 'es' : 'en',
            ...(isCRMImport() ? { "Authorization": 'Bearer ' + token } : {}),
          }
      }).then(response => {
          if(response.ok) {
              response.blob().then(blob => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = isCRMImport() ? 'User_mini_import_template.csv' : 'User_import_template.csv';
                  a.click();
              });
          } else {
              console.log('Error: ' + response.status);
          }
      }).catch(error => {
          console.log(error);
      });
  };

  return (
    <Styled.Wrapper>
      {layout !== 'OldUi' && <Breadcrumb filters={filters} />}
      <Styled.TopWrapper alignRight={!viewExportOptions}>
        {window.$environment.CURRENT_LAYOUT !== 'SpireonNew' &&
        (<Styled.SearchWrapper>
          <Search
            onChange={(e) => selectChange(e)}
            default={[...users.list]}
            fromData={isInsuracePortalUser()}
            placeHolder={i18n.t('vehicles.searchUser')}
            query={'full_name'}
            id='searchUser'
            onRemove={removeData}
          ></Search>
        </Styled.SearchWrapper>)
        }
        {!hasRestrictedRole([ROLES.VIEW_ONLY_FM]) && window.$environment.SERVICE_PROVIDER !== 'webfleet' &&(
        <Styled.IconWrapper>
          <LinkLi component={Link} to={isMechanicUser? pageUrls.addMechanic: pageUrls.addUser} className="userNew">
            <Icon src={getImagePath(imageUrls.addVehicle)} />
          </LinkLi>
          <LinkLi
            component={Link}
            to={pageUrls.addUser}
            className="addUserButtonhover userOld"
          >
            <Button
              component={Link}
              variant="body2"
              className="linkStyle addUser"
              label={i18n.t('users.addNewUser')}
            ></Button>
          </LinkLi>
        </Styled.IconWrapper>)
        }
        {viewExportOptions && 
        (<Styled.ImpExpWrapper>
          {window.$environment.SERVICE_PROVIDER !== 'webfleet' &&
          (<>
          <Export
            exportUserTemplate
            src={imageUrls.importUserTemplate}
            filename={'users'}
            toolTip={i18n.t('vehicles.importUserTemplate')}
            className="importUserTemplateContainer"
            onClick={() => exportUserTemplate()}
            buttonName={i18n.t('vehicles.importUserTemplate')}
            newUI={newUI}
          />
          <Import
            userImport
            className="importUserContainer userNew"
            toolTip={i18n.t('vehicles.importUserTemplate')}
            onClick={(e) => {
              if(window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && !isInsuracePortalUser()){
                setModalMessageObject({
                  content: () => (
                    <div className="deleteParent">
                      <div className="imageSection">
                        <img
                          src={getImagePath(imageUrls.confirmIcon)}
                          alt="Are you sure"
                        />
                      </div>
                      <h2>
                        {i18n.t('common.insurancePortalWarning')}

                      </h2>
                      <div className="deletePopupBtn">
                        <div className="cancelBtn">
                          <Button
                            label={ i18n.t('common.ok')}
                            type={'reset'}
                            onClick={() => {
                              setOpenMessageModal(false);
                              if (document.getElementById('fileSelect'))
                              document.getElementById('fileSelect').value = '';
                            }}
                          ></Button>
                        </div>
                        <div className="deletebtn">
                          <Button
                            label={ i18n.t('common.proceedToImport')}
                            onClick={() => {
                              setOpenMessageModal(false);
                              importUser(e)
                            }}
                          ></Button>
                        </div>
                      </div>
                    </div>
                  )
                });
                setOpenMessageModal(true);
              }else{
                importUser(e)
              }
              }}
            asBinary
            reqDataModel={'multipartFile'}
            buttonName={i18n.t('vehicles.importUserTemplate')}
            newUI={newUI}
          />
          <Import
            className="importUserContainer userOld"
            toolTip={i18n.t('vehicles.importUserTemplate')}
            onClick={(e) => importUser(e)}
            asBinary
            reqDataModel={'multipartFile'}
            importVehicle={i18n.t('common.importUser')}
            id="importVeh"
            newUI={newUI}
          />
          </>)}
          <Export
            src={imageUrls.exportUser}
            filename={'users'}
            toolTip={i18n.t('vehicles.exportUser')}
            className="exportContainer"
            onClick={() => exportUser()}
            buttonName={i18n.t('vehicles.exportUser')}
            newUI={newUI}
          />
        </Styled.ImpExpWrapper>)
        }
      <Modal open={openMessageModal} setOpen={setOpenMessageModal} data={ModalMessageObject} deletePopup />
        <iframe
          id="importUserTemplateFrame"
          style={{ display: 'none' }}
        ></iframe>

      </Styled.TopWrapper>
      <div className="breadcrumbClass">
        {layout === 'OldUi' && <Breadcrumb  filters={filters} />}
      </div>
    </Styled.Wrapper>
  );
};
export default TopActions;
