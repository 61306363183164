import { isCRMImport } from '@/utils/utils';
import {
  crmImportVehicleApi,
  crmImportVehicleApiPost,
  exportVehicleApi,
  fleetVehiclesApi,
  fleetVehiclesRadiusApi,
  getDriverNamesApi,
  importTrailerApi,
  importTrailerApiPost,
  importVehicleApi,
  importVehicleApiPost,
  trailerDeleteApi,
  vehicleDeleteApi,
  vehicleListApi,
  vehicleListDropDownApi
} from '../../../../config/apis';

const initialState = {
  vehicleList: [],
  vehicleListDropDown: [],
  vehicleFilteredList: [],
  trailerList: [],
  fleetVehicleList: [],
  fleetVehicleRadiusList: [],
  trailerFilteredList: [],
  infoBlockItemsList: [],
  infoBlockItemsFilteredList: [],
  driverNames: [],
  selectedRow: { index: -1, row: {} }
};

export const vehicles = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        vehicleList: [...payload.vehicles],
        vehicleFilteredList: [...payload.vehicles],
        trailerList: [...payload.trailers],
        trailerFilteredList: [...payload.trailers],
        infoBlockItemsList: [...(payload.infoBlockItems ? payload.infoBlockItems : [])],
        infoBlockItemsFilteredList: [...(payload?.infoBlockItems ? payload?.infoBlockItems : [])]
      };
    },
    fleetListSuccess(state, payload) {
      return {
        ...state,
        fleetVehicleList: [...payload]
      }
    },
    fleetRadiusListSuccess(state, payload) {
      return {
        ...state,
        fleetVehicleRadiusList: [...payload]
      }
    },
    clearRadiusList(state, payload) {
      return {
        ...state,
        fleetVehicleRadiusList: []
      }
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, vehicleFilteredList: [...list] };
    },
    filterBoth(state, list) {
      return { ...state, ...list };
    },
    trailerFilterSuccess(state, list) {
      return { ...state, trailerFilteredList: [...list] };
    },
    setSelectedItem(state, data) {
      return { ...state, selectedRow: { ...data } };
    },
    listUpdate(state, list) {
      return {
        ...state,
        trailerList: [...list],
        trailerFilteredList: [...list]
      };
    },
    updateListResponse(state, payload) {
      return {
        ...state,
        vehicleList: payload?.vehicles ? [...payload?.vehicles] : [],
        vehicleFilteredList: payload?.vehicles ? [...payload?.vehicles] : [],
        trailerList: payload?.trailers ? [...payload?.trailers] : [],
        trailerFilteredList: payload?.trailers ? [...payload?.trailers] : [],
        infoBlockItemsList: payload?.infoBlockItems ? [...payload?.infoBlockItems] : [],
        infoBlockItemsFilteredList: payload?.infoBlockItems ? [...payload?.infoBlockItems] : []
      };
    },
    driverName(state, payload) {
      return {
        ...state,
        driverNames: payload.response
      };
    },
    updateVehicleListWithDeletedItem(state, id) {
      const updatedVehicleList = state.vehicleFilteredList.filter(
        (item) => item.id !== id
      );
      return {
        ...state,
        vehicleFilteredList: updatedVehicleList,
      }
    },
    getVehicleListDropDownSuccess(state, payload) {
      return { ...state, vehicleListDropDown: [...payload] };
    },
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: vehicleListApi
          },
          id: payload.apiId
        });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchFleetData(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...fleetVehiclesApi,
              url: fleetVehiclesApi.url.replace('{id}', payload.data.id)
            }
          },
          id: payload.apiId
        });
        response && this.fleetListSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchRadiusData(payload) {
      if (payload.data.vehicleId) {
        try {
          const response = await dispatch.asyncRequests.handleAsyncRequests({
            payload: {
              api: {
                ...fleetVehiclesRadiusApi,
                url: fleetVehiclesRadiusApi.url + `?companyId=${payload.data.companyId}&vehicleId=${payload.data.vehicleId}`
              }
            },
            id: payload.apiId
          });
          response && this.fleetRadiusListSuccess(response);
        } catch (error) {
          this.clearRadiusList();
        }
      } else this.clearRadiusList()
    },
    async handleDeleteAlert(payload, rootState, forceDelete) {
      try {
        let response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...vehicleDeleteApi,
              url: forceDelete ? `${vehicleDeleteApi.url.replace('{id}', payload.data.id)}?forceDelete=true` : vehicleDeleteApi.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        //remove item
        payload.callback('success', response);
        if(response) {
          this.fetchData({apiId:'fetchVehiclesList'})
        }
        // this.updateVehicleListWithDeletedItem(payload.data.id)
      } catch (error) {
        if (error.message === 'There are active service requests existing for this vehicle') {
          payload.callback('warning', 'There are active service requests existing for this vehicle are you sure want to delete it', () => { this.handleDeleteAlert(payload, rootState, true) }, false, true);
          return;
        }
        // this.listFailed();
      }
    },
    async handleExportVehicle(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: exportVehicleApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleImportVehicle(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: isCRMImport() ? crmImportVehicleApi : importVehicleApi,
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleImportTrailer(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: importTrailerApi
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleImportVehiclePost(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: isCRMImport() ? crmImportVehicleApiPost : importVehicleApiPost,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        if (response?.success && response.success.length > 0) payload.callback('success', 'Data has been imported successfully');
        const messageHandler = ((string, data) => {
          let messageString = string;
          for (let i = 0; i < data.length; i++) {
            messageString += `${data[i].name} `
          }
          return messageString
        })
        if (response?.error && response.error.length > 0 && response.success.length > 0) payload.callback('success', messageHandler('Upload successful. Except Following ', response?.error));
        if (response?.error && response.error.length > 0 && response.success.length === 0) payload.callback('error', "Some error occurred while calling WiredRhino API for the Vehicles");
      } catch (error) {
        // this.listFailed();
      }
    },
    async handleImportTrailerPost(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: importTrailerApiPost,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback
        });
        //remove item
        payload.callback('success', response);
      } catch (error) {
        // this.listFailed();
      }
    },
    updateTrailerList(id, state) {
      let filtered = state.vehicles.trailerFilteredList.filter(
        (item) => item.id !== id
      );
      this.listUpdate(filtered);
    },
    async getDriverNames(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: getDriverNamesApi,
            data: payload.data
          },
          id: payload.apiId,
          callback: payload.callback,
        });
        //remove item
        // response && this.driverName(response);
        payload.callback('success', response, payload);
      } catch (error) { }
    },
    async handleTrailerDeleteAlert(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...trailerDeleteApi,
              url: trailerDeleteApi.url.replace('{id}', payload.data.id)
            }
          },
          callback: payload.callback,
          id: payload.id
        });
        //remove item
        payload.callback('success', response);
        response && dispatch.vehicles.updateTrailerList(payload.data.id);
      } catch (error) {
        // this.listFailed();
      }
    },
    async fetchVehicleListDropDown(payload) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: vehicleListDropDownApi
          },
          id: payload.apiId
        });
        response && this.getVehicleListDropDownSuccess(response);
      } catch (error) {
        // this.listFailed();
      }
    },
  })
};
