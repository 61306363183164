import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import RenderComponent from './RenderComponent';
import { hasValue } from '../../../utils/utils';
import { emailRegExp } from '@/utils/constants';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const DotAudit = ({ apiStatus, sendFmcsa, callback, drivers, Styled, canada }) => {
  const { i18n } = useTranslation();

  const apiId = 'dotAudit';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);

  return (
    <Styled.Wrapper className="formSection">
      <Form
        type="server"
        direction="row"
        canadaForm={canada}
        initialValues={{
          driverId: '',
          startDate: '',
          endDate: '',
          outputFileComment: ''
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          sendFmcsa({
            data: { ...fields, driverId: fields.driverId.value },
            apiId,
            callback: callback
          });
        }}
        customProps={{ hasApiStatus, drivers, Styled }}
        validationSchema={Yup.object().shape({
          ...(canada ? {email: Yup.string().matches(emailRegExp, i18n.t('common.emailValidationMessage')).required(i18n.t('This field is required')),} : {}),
          driverId: Yup.string().nullable().required(i18n.t('This field is required')),
          startDate: Yup.string().required(i18n.t('This field is required')),
          endDate: Yup.string().required(i18n.t('This field is required')),
          ...(canada ? {} : {outputFileComment: Yup.string().required(i18n.t('This field is required'))})
        })}
      />

      {!canada && <Message type="info" message={'FDCSA info'}></Message>}
    </Styled.Wrapper>
  );
};

DotAudit.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  sendFmcsa: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
};

export default DotAudit;
